import { Ionicons } from '@expo/vector-icons'
import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Modal, Platform, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native'
import DraggableFlatList, { ScaleDecorator } from 'react-native-draggable-flatlist'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import uuid from 'react-native-uuid'
import { playlistSortByName } from '../../Helpers/sortArray'
import { USER_PLAYLIST_URL, colors } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import Track from '../../Screens/TracksScreen/components/Track'
import { SecondaryButton } from '../Buttons/Button'
import Loader from '../Loader/Loader'
import { Heading } from '../Typography/Typography'
import { NotificationModal } from './NotificationModal'
import * as Sentry from 'sentry-expo'

const QueueModal = ({ isModalOpen, setModalOpen, trackList, currentPlayingTrackIndex, setTrackList }) => {

	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	
	useEffect(() => {
		handleResize()
	}, [width])
	
	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}
	
	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			margin: 20,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: 30,
			paddingBottom: 45,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			minWidth: isWideScreen ? 700 : isSmallScreen ? 240  : 360,
			maxWidth: isWideScreen ? 700 : isSmallScreen ? 240  : 360,
			height: '90%'
		},
		label: {
			marginBottom: 20,
			color: 'white',
			fontSize: 20,
			marginTop: 10
		},
		errorMessage: {
			color: 'red',
			marginBottom: 10
		}
	})
	
	const [userPlaylists, setUserPlaylists] = useState([])
	const api = useAxios()
	const [queueTrackList, setQueueTrackList] = useState(undefined)
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})
	const [isModalOpenState, setIsModalOpenState] = useState(isModalOpen)

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const updateUserPlaylists = (id, name, description, visibility) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id: id, name: name, description: description, visibility: visibility })
		userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
		setUserPlaylists(userPlaylistsTemp)
	}

	useEffect(() => {
		async function boot() {
			await api
				.get(USER_PLAYLIST_URL)
				.then(({ data }) => {
					data = playlistSortByName(data)
					setUserPlaylists(data)
				})
				.catch(error => {
					console.log(error)
					if (Platform.OS === 'web') Sentry.Browser.captureException(error)
					else Sentry.Native.captureException(error)
				})
		}

		boot()
	}, [])

	useEffect(() => {
		console.log('initialLoad: ' + currentPlayingTrackIndex)
		let tempList = []
		for (let i = 0; i < trackList.length; i++) {
			let item = trackList[i]
			if (i > currentPlayingTrackIndex) {
				tempList.push(item)
			}
		}
		setQueueTrackList(tempList)
	}, [currentPlayingTrackIndex])

	const changePosition = (from, to, data) => {
		let newTracks = trackList.slice(0, currentPlayingTrackIndex)
		data.map(item => {
			newTracks.push(item)
		})

		setTrackList(newTracks)
		setQueueTrackList(data)
	}

	const renderItem = useCallback(
		({ item, index, drag, isActive }) => {
			return (
				<ScaleDecorator>
					{/* {item.visible && ( */}
					<TouchableOpacity
						onLongPress={drag}
						disabled={isActive}
						style={{ padding: 10, backgroundColor: isActive ? colors.accent : colors.secondary }}
					>
						<View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
							<Ionicons name="move" size={22} color={'white'} style={{ marginHorizontal: 10 }} />
							<Track
								edit={false}
								track={item.track}
								tracksList={trackList}
								playlistTrackId={item.id}
								playlistId={item.playlist}
								userPlaylists={userPlaylists}
								updateUserPlaylists={updateUserPlaylists}
								openNotifModal={openNotifModal}
								closeNotifModal={closeNotifModal}
								isRepeatAllTracks={false}
								item={item}
								trackIndex={index}
								isQueue={true}
							/>
						</View>
					</TouchableOpacity>
					{/* )} */}
				</ScaleDecorator>
			)
		},
		[queueTrackList]
	)

	return (
		isModalOpenState && (
			<Modal
				animationType="slide"
				transparent={true}
				visible={isModalOpenState}
				onRequestClose={() => {
					setIsModalOpenState(false)
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<Heading>Kø</Heading>
						<View
							style={{ flexDirection: 'row', marginTop: 20, height: '86%', overflowY: 'scroll' }}
							key={'akjdsfal' + uuid.v4()}
						>
							{queueTrackList ? (
								<View>
									{queueTrackList.length > 0 ? (
										<GestureHandlerRootView>
											<DraggableFlatList
												style={{ width: 600, minWidth: 300 }}
												data={queueTrackList}
												onDragEnd={({ data, from, to }) => {
													changePosition(from, to, data)
												}}
												renderItem={renderItem}
												keyExtractor={item => item.id}
											/>
										</GestureHandlerRootView>
									) : (
										<Text style={{ color: 'white' }}>Ingen sange...</Text>
									)}
								</View>
							) : (
								<Loader bgColor={colors.secondary} />
							)}
						</View>
						<View style={{ flexDirection: 'row', justifyContent: 'center' }}>
							<SecondaryButton
								title={'Luk'}
								onPress={() => {
									setIsModalOpenState(false)
									setModalOpen(false)
								}}
								style={{ width: 180, margin: 10 }}
							/>
						</View>
					</View>
				</View>
				{notifModal.visible && (
					<NotificationModal
						title={notifModal.title}
						description={notifModal.description}
						visible={notifModal.visible}
						closeNotifModal={closeNotifModal}
						isDisappearing={notifModal.isDisappearing}
					/>
				)}
			</Modal>
		)
	)
}
export default React.memo(QueueModal)
