import { getHeaderTitle } from '@react-navigation/elements'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { colors } from '../../Helpers/variables'
import AlbumTracksScreen from '../../Screens/AlbumTracksScreen/AlbumTracksScreen'
import ArtistTracksScreen from '../../Screens/ArtistTracksScreen/ArtistTracksScreen'
import CreatePlaylistScreen from '../../Screens/CreatePlaylistScreen/CreatePlaylistScreen'
import ContainerScreen from '../../Screens/HomeScreen/ContainerScreen'
import GenreTracksScreen from '../../Screens/GenreTracksScreen/GenreTracksScreen'
import InternalContainerPlaylistsScreen from '../../Screens/InternalContainerPlaylistsScreen/InternalContainerPlaylistsScreen'
import InternalContainerScreen from '../../Screens/InternalContainerScreen/InternalContainerScreen'
import MusicTherapistsScreen from '../../Screens/MusicTherapistScreen/MusicTherapistsScreen'
import MyPlaylistScreen from '../../Screens/MyPlaylistScreen/MyPlaylistScreen'
import PlaylistScreen from '../../Screens/PlaylistScreen/PlaylistScreen'
import PlaylistTracksScreen from '../../Screens/PlaylistTracksScreen/PlaylistTracksScreen'
import SearchScreen from '../../Screens/SearchScreen/SearchScreen'
import SongRequestScreen from '../../Screens/SongRequestScreen/SongRequestScreen'
import TracksScreen from '../../Screens/TracksScreen/TracksScreen'
import YearTracksScreen from '../../Screens/YearTracksScreen/YearTracksScreen'
import AuthService from '../../Services/AuthService'
import Header from '../Layouts/Header'
import MainLayout from '../Layouts/MainLayout'
import CategoryScreen from '../../Screens/HomeScreen/CategoryScreen'
import SubContainerScreen from '../../Screens/HomeScreen/SubContainerScreen'
import SubContainerTreeScreen from '../../Screens/HomeScreen/SubContainerMenuTreeScreen'
import PersonalMusicMenuScreen from '../../Screens/PersonalMusicScreen/PersonalMusicMenuScreen'
import MyYouthMusicScreen from '../../Screens/PersonalMusicScreen/MyYouthMusicScreen'
import MyFavoriteMusicScreen from '../../Screens/PersonalMusicScreen/MyFavoriteMusicScreen'
import MyWellKnownCalmMusicScreen from '../../Screens/PersonalMusicScreen/MyWellKnownCalmMusicScreen'
import MyProfessionallySelectedMusicScreen from '../../Screens/PersonalMusicScreen/MyProfessionallySelectedMusicScreen'
import ProfileListScreen from '../../Screens/PersonalMusicScreen/ProfileListScreen'
import ProfileInformationScreen from '../../Screens/PersonalMusicScreen/ProfileInformationScreen'
import ResultArtist from '../../Screens/PersonalMusicScreen/SuggestedResultsScreens/ResultArtist'
import ResultComposer from '../../Screens/PersonalMusicScreen/SuggestedResultsScreens/ResultComposer'
import ResultGenre from '../../Screens/PersonalMusicScreen/SuggestedResultsScreens/ResultGenre'

const Stack = createNativeStackNavigator()

export default function MainStack() {
	return (
		<MainLayout>
			<Stack.Navigator
				initialRouteName={'Home'}
				screenOptions={{
					header: ({ navigation, route, options }) => (
						<Header navigation={navigation} title={getHeaderTitle(options, route.name)} />
					),
					headerStyle: { backgroundColor: colors.primary },
					headerBackTitleVisible: false,
					headerShadowVisible: false,
					headerTintColor: 'white',
					contentStyle: { backgroundColor: colors.primary }
				}}
			>
				<Stack.Screen options={{ title: 'Hjem' }} name="CategoryScreen" component={CategoryScreen} />
				<Stack.Screen options={{ title: 'Kategorier' }} name="ContainerScreen" component={ContainerScreen} />
				<Stack.Screen
					options={{ title: 'Internt Miljø' }}
					name="InternalContainerScreen"
					component={InternalContainerScreen}
				/>
				<Stack.Screen options={{ title: 'Spillelister' }} name="PlaylistScreen" component={PlaylistScreen} />
				<Stack.Screen options={{ title: 'Mine Spillelister' }} name="MyPlaylistsScreen" component={MyPlaylistScreen} />
				<Stack.Screen
					options={{ title: 'Internt Spillelister' }}
					name="InternalPlaylistScreen"
					component={InternalContainerPlaylistsScreen}
				/>
				<Stack.Screen options={{ title: 'Spilleliste numre' }} name="TracksScreen" component={TracksScreen} />
				<Stack.Screen options={{ title: 'Søg' }} name="SearchScreen" component={SearchScreen} />
				<Stack.Screen
					options={{ title: 'Musikterapeuter' }}
					name="MusicTherapistsScreen"
					component={MusicTherapistsScreen}
				/>
				<Stack.Screen name="ArtistTracks" options={{ title: 'Kunstner Sange' }} component={ArtistTracksScreen} />
				<Stack.Screen name="AlbumTracks" options={{ title: 'Album Sange' }} component={AlbumTracksScreen} />
				<Stack.Screen name="YearTracks" options={{ title: 'Year Sange' }} component={YearTracksScreen} />
				<Stack.Screen name="GenreTracks" options={{ title: 'Genre Sange' }} component={GenreTracksScreen} />
				<Stack.Screen name="PlaylistTracks" options={{ title: 'Playlist Sange' }} component={PlaylistTracksScreen} />
				<Stack.Screen
					name="CreatePlaylistScreen"
					options={{ title: 'Opret Spilleliste' }}
					component={CreatePlaylistScreen}
				/>
				<Stack.Screen name="SongRequestScreen" options={{ title: 'Anmod om en sang' }} component={SongRequestScreen} />
				<Stack.Screen options={{ title: 'Spillelister' }} name="SubContainerScreen" component={SubContainerScreen} />
				<Stack.Screen options={{ title: 'Spillelister' }} name="SubContainerTreeScreen" component={SubContainerTreeScreen} />
				<Stack.Screen options={{ title: 'Det personlige MusicMind' }} name="PersonalMusicMenuScreen" component={PersonalMusicMenuScreen} />
				<Stack.Screen options={{ title: 'Min ungdomstids musik' }} name="MyYouthMusicScreen" component={MyYouthMusicScreen} />
				<Stack.Screen options={{ title: 'Min favoritmusik' }} name="MyFavoriteMusicScreen" component={MyFavoriteMusicScreen} />
				<Stack.Screen options={{ title: 'Min kendte rolige musik' }} name="MyWellKnownCalmMusicScreen" component={MyWellKnownCalmMusicScreen} />
				<Stack.Screen options={{ title: 'Fagligt udvalgt musik til mig' }} name="MyProfessionallySelectedMusicScreen" component={MyProfessionallySelectedMusicScreen} />
				<Stack.Screen options={{ title: 'Profiler' }} name="ProfileListScreen" component={ProfileListScreen} />
				<Stack.Screen options={{ title: 'Profilinformation' }} name="ProfileInformationScreen" component={ProfileInformationScreen} />
				<Stack.Screen options={{ title: 'Min ungdomstids musik' }} name="ResultArtist" component={ResultArtist} />
				<Stack.Screen options={{ title: 'Min ungdomstids musik' }} name="ResultComposer" component={ResultComposer} />
				<Stack.Screen options={{ title: 'Min ungdomstids musik' }} name="ResultGenre" component={ResultGenre} />
			</Stack.Navigator>
		</MainLayout>
	)
}
