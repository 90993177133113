/* ----------------------------- Library imports ---------------------------- */
import { useEffect, useState } from 'react'
import { ImageBackground, Platform, ScrollView, StyleSheet, Text, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'

/* --------------------------- Component Imports ---------------------------- */
import Loader from '../../Components/Loader/Loader'

/* ------------------------------ Hooks imports ----------------------------- */
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'

/* ------------------------------ Data helpers ------------------------------ */
// import { addDataToSubContainersTable, retrieveDataFromSubContainersTable } from '../../Helpers/database/subContainersTable'
import { MAIN_CONTAINER_CATEGORY_BOX_SUBS_TREE_URL } from '../../Helpers/variables'

/* ---------------------------- Exception handler --------------------------- */
import RefreshButton from '../../Components/RefreshButton'
import { captureException } from '../../Helpers/sentryLog'
import ContainerTree from './components/ContainerTree'
import SvgItem from './components/SvgItem'
import { FlatList } from 'react-native-gesture-handler'
import { useNavigation } from '@react-navigation/native'

export default function SubContainerTreeScreen({ categoryId, categoryName, cancelModalCallback, backgroundColor }) {
	const api = useAxios()

	const { deviceType, isOffline, shouldReset, setShouldReset } = useLayout()

	const [subContainers, setSubContainers] = useState([])
	// const [originalSubContainers, setOriginalSubContainers] = useState([])
	const [loading, setLoading] = useState(true)
	const [wasDataCached, setWasDataCached] = useState(false)

	const [shouldRefresh, setShouldRefresh] = useState(false)
	const [error, setError] = useState(false)
	const navigation = useNavigation()

	// const getSubContainers = mainSubs => {
	// 	let newContainers = []
	// 	mainSubs.map(async item => {
	// 		let url =
	// 			SUB_CONTAINER_BOXES_URL +
	// 			'?level=' +
	// 			(item.level + 1) +
	// 			'&container=' +
	// 			item.container_group +
	// 			'&category=' +
	// 			item.category_group

	// 		url = url + '&main_sub_container=' + item.sub_container

	// 		let { data } = await api.get(url)

	// 		if (data.length > 0) item.hasNextSubContainer = true
	// 		else item.hasNextSubContainer = false

	// 		newContainers.push(item)

	// 		if (newContainers.length === mainSubs.length) {
	// 			newContainers = playlistSortByPosition(newContainers)
	// 			setSubContainers(newContainers)
	// 			setLoading(false)
	// 			// setOriginalSubContainers(data)
	// 			// addDataToSubContainersTable(data)
	// 		}
	// 	})
	// }

	const getDataFromApi = async () => {
		setLoading(true)
		// const routeParams = route.params ? route.params : global.routeParams
		// let url =
		// 	SUB_CONTAINER_BOXES_URL +
		// 	'?level=' +
		// 	routeParams.level +
		// 	'&container=' +
		// 	routeParams.containerId +
		// 	'&category=' +
		// 	routeParams.categoryId
		// 	if (routeParams.level !== 1) url = url + '&main_sub_container=' + routeParams.main_sub_container
		const { data } = await api.get(MAIN_CONTAINER_CATEGORY_BOX_SUBS_TREE_URL + categoryId)
		// const { data } = await api.get(url)
		// if (data.MainContainerBoxSubs.length === 0) {
		setSubContainers(data)
		setLoading(false)
		// } else {
		// 	// await getSubContainers(data)
		// }
	}

	// useEffect(() => {
	// 	setError(false)
	// 	// setLoading(true)
	// 	async function boot() {
	// 		try {
	// 			// if (Platform.OS === 'web') {
	// 			// 	await getDataFromApi()
	// 			// 	return
	// 			// }

	// 			await getDataFromApi()
	// 		} catch (error) {
	// 			console.error(error)
	// 			captureException(error)
	// 			setError(true)
	// 		} finally {
	// 			// setLoading(false)s
	// 			setShouldReset(false)
	// 		}
	// 	}

	// 	boot()
	// }, [route.params ? route.params.level : global.routeParams.level])

	useEffect(() => {
		if (shouldReset) {
			setError(false)
			setLoading(true)
			async function boot() {
				try {
					if (Platform.OS === 'web') {
						await getDataFromApi()
						return
					}

					await getDataFromApi()
				} catch (error) {
					console.error(error)
					captureException(error)
					setError(true)
				} finally {
					setLoading(false)
					setShouldReset(false)
				}
			}

			boot()
		}
	}, [shouldReset])

	useEffect(() => {
		setError(false)
		async function boot() {
			try {
				if (Platform.OS === 'web' || !isOffline) {
					await getDataFromApi()
					return
				}
				await getDataFromApi()
				// const cachedData = await retrieveDataFromSubContainersTable()
				// if (!isCachedDataValid(cachedData)) {
				// 	await getDataFromApi()
				// } else {
				// 	setWasDataCached(true)
				// 	setSubContainers(cachedData)
				// 	setOriginalSubContainers(cachedData)
				// }
			} catch (error) {
				console.error(error)
				captureException(error)
				setError(true)
			} finally {
				setLoading(false)
			}
		}

		boot()
	}, [shouldRefresh])

	if (error) return <RefreshButton setShouldRefresh={setShouldRefresh} />
	return (
		<ScrollView style={{ flex: 1, width: '95%' }}>
			{/* <ScrollView> */}
			<FlatList
				data={subContainers.MainContainerBoxSubs}
				renderItem={({ item, index }) => (
					<View style={{ flex: 1, alignItems: 'center' }}>
						<View style={{ marginLeft: 8 }}>
							<SvgItem title={item.container_group_name} 
								hasNextRow={subContainers.SubContainerBoxs.filter(x => x.container_group === item.container_group
									&& x.category_group === item.category_group && x.level === 1).length > 0 ? true : false}
								key={item.container_group_name + index}
								container_group={item.container_group}
								cancelModalCallback={cancelModalCallback}
								sub_container={item.id}
								backgroundColor={backgroundColor}
							/>
						</View>
						<View>
							{
								subContainers.SubContainerBoxs.filter(x => x.container_group === item.container_group
									&& x.category_group === item.category_group && x.level === 1).length > 0 ?
									<ContainerTree
										subContainers={subContainers.SubContainerBoxs.filter(x =>
											x.container_group === item.container_group
											&& x.category_group === item.category_group && x.level === 1)}
										subContainersAllLevelsExceptCurrLevel={subContainers.SubContainerBoxs.filter(x =>
											x.container_group === item.container_group
											&& x.category_group === item.category_group && x.level !== 1)}
										currentLevel={1}
										cancelModalCallback={cancelModalCallback}
										backgroundColor={backgroundColor}
									/>
									: undefined
							}
						</View>
					</View>
				)}
				ListEmptyComponent={<Text style={{ color: 'white' }}>Ingen data...</Text>}
				keyExtractor={item => item.id}
				contentContainerStyle={{ flex: 1, flexDirection: 'row' }}
				style={{ flex: 1, flexDirection: 'row' }}
				horizontal={true}
			/>
		</ScrollView>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		//   paddingTop: <Constants></Constants>.statusBarHeight,
		backgroundColor: "#ecf0f1",
		padding: 8,
	},
});