import { useEffect, useState } from 'react';
import { ImageBackground, Modal, StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import { colors } from '../../Helpers/variables';
import useTokens from '../../Hooks/useTokens';
import { SecondaryButton } from '../Buttons/Button';
import SubContainerTreeScreen from '../../Screens/HomeScreen/SubContainerTreeScreen';

export const SubcontainerTreeChartModal = ({
    modalVisible,
    cancelCallback,
    categoryId,
    categoryName,
    backgroundColor
}) => {

    const { width, height } = useWindowDimensions();
    const [isWideScreen, setIsWideScreen] = useState(width > 700 && height > 1000)
    const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

    const handleResize = () => {
        setIsWideScreen(width > 700 && height > 1000)
        setIsSmallScreen(width <= 320)
    }

    useEffect(() => {
        handleResize()
    }, [width, height])

    const getModalWidth = () => {
        if (isWideScreen) {
            if (width < 800) {
                return '98%'
            }

            return '98%'
        } else if (isSmallScreen) {
            return '90%'
        } else {
            return '98%'
        }
    }

    const modalStyle = StyleSheet.create({
        centeredView: {
            flex: 1,
            alignItems: 'center'
        },
        modalView: {
            backgroundColor: colors.secondary,
            borderRadius: 20,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            width: getModalWidth(),
            height: isWideScreen ? '95%' : '90%',
            marginTop: 20,
        },
        label: {
            color: 'white',
            fontSize: isWideScreen ? 20 : 18,
            marginLeft: isWideScreen ? 20 : 0
        },
        errorMessage: {
            color: 'red',
            fontSize: isWideScreen ? 16 : 14,
            marginRight: '10%',
        },
        buttonTextStyle: {
            color: colors.primary,
            fontWeight: 'bold'
        }
    })

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                cancelCallback()
            }}
        >
            <View style={modalStyle.centeredView}>
                <View style={modalStyle.modalView}>
                    {/* <View>
                        <SecondaryButton
                            title='Annuller'
                            onPress={() => cancelCallback()}
                            style={{
                                width: isWideScreen ? 120 : '90%',
                                minHeight: 20,
                                marginRight: isWideScreen ? 20 : 10
                            }}
                        />
                    </View> */}
                    {/* <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', zIndex: 1, marginTop: 10 }} > */}

                    <ImageBackground
                        style={{
                            width: '100%', height: '100%', borderRadius: 10,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        resizeMode="cover"
                        onError={() => console.log('Error loading image...')}
                        source={require('../../assets/subcontainer-bg.png')}
                    >
                        <View style={{
                            width: '100%', flexDirection: 'row', justifyContent: 'space-between',
                            alignItems: 'center', marginTop: 10
                        }} >
                            <Text style={{ color: 'white', fontSize: 24, marginLeft: 10 }}>{categoryName.replace(/\n/g, ' ')}</Text>
                            <SecondaryButton
                                title='Annuller'
                                onPress={() => cancelCallback()}
                                style={{
                                    width: 120,
                                    minHeight: 20,
                                    marginRight: isWideScreen ? 20 : 10
                                }}
                            />
                        </View>
                        <SubContainerTreeScreen categoryId={categoryId} categoryName={categoryName} cancelModalCallback={cancelCallback}
                            backgroundColor={backgroundColor} />
                    </ImageBackground>
                </View>
            </View>
        </Modal >
    )
}
