import React, { useEffect, useState } from 'react'
import { Text, View, useWindowDimensions, StyleSheet, Image, TouchableHighlight, Pressable } from 'react-native'
import Loader from '../../Components/Loader/Loader'
import { colors } from '../../Helpers/variables'
import { ScrollView } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { PrimaryButton } from '../../Components/Buttons/Button'
import { FlatList } from 'react-native-web'
import { Ionicons } from '@expo/vector-icons'
import { ConfirmationModal } from '../../Components/Modals/ConfirmationModal'
import { NotificationModal } from '../../Components/Modals/NotificationModal'

export default function ProfileListScreen() {
	const [loading, setLoading] = useState(true)
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	
	const emptyLabel = 'Ingen spillelister'
	const navigation = useNavigation()
	const [deleteProfileConfirmationModal, setDeleteProfileConfirmationModal] = useState(false)

	const handleResize = () => {
		setIsWideScreen(width > 700)
	}

	useEffect(() => {
		handleResize()
	}, [width])
	
	useEffect(() => {
		setLoading(false)
	}, [])

	const [tempData, setTempData] = useState([
		{
			id: 1,
			name: 'John',
			birthYear: 2001,
			childhoodResidence: 'California USA, Manila Philippines',
			musicMemories: 'En god dag og godt vejr, mens man rejser tværs gennem landet i bil.',	
			unwantedMusic: 'None',
			screen1: {
				childhoodMusic: 'Bruno Mars, Air Supply',
				childhoodHomeMusic: 'Abstract',
			},
			screen2: {
				childhoodMusic: 'Air Supply, Michael Learns To Rock',
				childhoodHomeMusic: 'Blues',
			},
			screen3: {
				childhoodMusic: 'Drake, Eminem',
				childhoodHomeMusic: 'Metal',
			},
			screen4: {
				childhoodMusic: 'Celine Dion, Mariah Carey',
				childhoodHomeMusic: 'Reggae',
			}
		},
		{
			id: 2,
			name: 'Grete',
			birthYear: 1998,
			childhoodResidence: 'Copenhagen, Denmark',
			unwantedMusic: 'None',
			musicMemories: 'Nyder udsigten over byens lys sammen med min mand.',
			screen1: {
				childhoodMusic: 'Lucas Graham, Kim Larsen',
				childhoodHomeMusic: 'Reggae',
			},
			screen2: {
				childhoodMusic: 'King Diamond, Anne Linnet',
				childhoodHomeMusic: 'Metal',
			},
			screen3: {
				childhoodMusic: 'Victor Borge, Volbeat',
				childhoodHomeMusic: 'Abstract',
			},
			screen4: {
				childhoodMusic: 'Mercyful Fate, Soren Andersen',
				childhoodHomeMusic: 'Blues',
			}
		},
		{
			id: 3,
			name: 'Hans',
			birthYear: 1970,
			childhoodResidence: 'Canberra Australia',
			unwantedMusic: 'None',
			musicMemories: 'Mindes mine gymnasiedage med nære venner.',
			screen1: {
				childhoodMusic: 'Guy Sebastian, Sia',
				childhoodHomeMusic: 'Blues',
			},
			screen2: {
				childhoodMusic: 'Kylie Minogue, Nick Cave',
				childhoodHomeMusic: 'Metal',
			},
			screen3: {
				childhoodMusic: 'Lenka, Nicole Car',
				childhoodHomeMusic: 'Reggae',
			},
			screen4: {
				childhoodMusic: 'Keith Urban, Stephen Cummings',
				childhoodHomeMusic: 'Abstract',
			}
		},
	])

	const styles = StyleSheet.create({
        profiler: {
            flexDirection: 'row',
            flex: 1,
            width: '100%',
			alignItems: 'center',
			justifyContent: 'space-between'
        },
		container: {
			flex: 1,
			marginLeft: isWideScreen ? 30 : 10,
			marginRight: isWideScreen ? 30 : 5,
			color: 'white',
			marginTop: isWideScreen ? 0 : 5,
            width: isWideScreen ? '70%' : '90%',
			minHeight: 300
		},
		titleText: {
			color: 'white',
			fontSize: isWideScreen ? 25 : 20,
			margin: 10,
			fontWeight: 'bold'
		}
    })

	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const openNotifModal = (description) => {
		setNotifModal({
			visible: true,
			title: '',
			description: description,
			isDisappearing: false
		})
	}
	
	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const handleProfileDeletion = () => {
		setDeleteProfileConfirmationModal(false)
		openNotifModal('Profil blev slettet.')
	}

	const cancelCallbackDeleteProfile = () => {
		setDeleteProfileConfirmationModal(false)
	}

	return loading ? (
		<Loader />
	) : (
		<ScrollView style={{ backgroundColor: colors.boxes, height: height - 85 }}>
			<View style={styles.profiler}>
				<View>
					<Text style={styles.titleText}>Profiler</Text>
				</View>
				<View>
					<PrimaryButton
						style={{ width: isWideScreen ? 90 : 70, minHeight: 30, marginRight: 20 }}
						title="Opret ny"
						onPress={() => navigation.navigate('ProfileInformationScreen', {isNew: true})}
					/>
				</View>
            </View>
			<View>
				<ScrollView style={styles.container}>
					<FlatList
						data={tempData}
						ListEmptyComponent={
							<Text style={{ color: colors.accent, margin: isWideScreen ? 10 : 5, fontSize: isWideScreen ? 20 : 18 }}>{emptyLabel}...</Text>
						}
						renderItem={({ item }) => (
							<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
								<View>
									<Pressable
										onPress={() => {
											navigation.navigate('PersonalMusicMenuScreen', {profile: item})
										}} 
										key={`selectProfile-${item.id}`}
									>
										<Text style={{color: 'white', fontSize: isWideScreen ? 20 : 18, padding: 10}}>
											{item.name}
										</Text>
									</Pressable>
								</View>
								<View  style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
									<Pressable
										onPress={() => {
											navigation.navigate('ProfileInformationScreen', {selectedItem: item})
										}} 
										key={`editProfile-${item.id}`}
									>
										<Ionicons name="create" size={20} color={'white'} />
									</Pressable>
									<Pressable
										onPress={() => {
											setDeleteProfileConfirmationModal(true)
										}} 
										key={`deleteProfile-${item.id}`}
									>
										<Ionicons name="trash" size={20} color={'white'} />
									</Pressable>
								</View>
							</View>
						)}
						keyExtractor={item => item.id}
					/>
				</ScrollView>
			</View>

			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					setModalVisibleExternal={undefined}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}

			{deleteProfileConfirmationModal && (
				<ConfirmationModal
					handleSubmit={handleProfileDeletion}
					modalVisible={deleteProfileConfirmationModal}
					cancelCallback={cancelCallbackDeleteProfile}
					description={'Vil du slette denne profil?'}
				/>
			)}

		</ScrollView>
	)
}
