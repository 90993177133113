import React, { useEffect, useState } from 'react'
import { Text, View, useWindowDimensions, StyleSheet, Image, TouchableHighlight } from 'react-native'
import Loader from '../../Components/Loader/Loader'
import { colors, SEARCH_ARTISTS_URL, SEARCH_GENRE_URL } from '../../Helpers/variables'
import { ScrollView } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { PrimaryButton } from '../../Components/Buttons/Button'
import useAxios from '../../Hooks/useAxios'
import ResultArtist from './SuggestedResultsScreens/ResultArtist'
import ResultComposer from './SuggestedResultsScreens/ResultComposer'
import ResultGenre from './SuggestedResultsScreens/ResultGenre'

export default function MyFavoriteMusicScreen() {

	const api = useAxios()
	const route = useRoute()
	const { profile } = route.params

	const [loading, setLoading] = useState(true)
    const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	const navigation = useNavigation()

	const [artistResults, setArtistResults] = useState({Artists: []})
	const [composerResults, setComposerResults] = useState({Artists: []})
	const [genreResults, setGenreResults] = useState({Genre: []})

	const handleResize = () => {
		setIsWideScreen(width > 700)
	}

	useEffect(() => {
		handleResize()
	}, [width])

    const styles = StyleSheet.create({
        mainBackground: {
            backgroundColor: colors.boxes,
            height: height - 85
        },
        profiler: {
            marginLeft: 10,
            justifyContent: 'space-between',
            flexDirection: 'row',
            flex: 1,
            width: '100%',
			borderColor: 'gray',
			borderBottomWidth: 1,
			marginBottom: 5,
			paddingBottom: 3
        },
        profilerButton: {
			width: 90,
            minHeight: 30,
            margin: 20 
        },
		titleText: {
			color: 'white',
			fontSize: isWideScreen ? 25 : 20,
			margin: 10,
			fontWeight: 'bold'
		},
		subheaderText: {
			color: 'white',
			fontSize: isWideScreen ? 22 : 16,
			margin: 10,
		},
		profileNameText: {
			color: colors.accent,
			fontSize: isWideScreen ? 25 : 20,
			margin: 10,
			fontWeight: 'bold'
		}
    })
    
	useEffect(() => {
		const tempArtist = profile.screen2.childhoodMusic.split(',')
		loadArtists(tempArtist[0].trim())
		loadComposers(tempArtist[1].trim())
		loadGenre(profile.screen2.childhoodHomeMusic)
	}, [])

	const loadArtists = async (input) => {
		setLoading(true)
		const url = SEARCH_ARTISTS_URL + input
		const { data } = await api.get(url)
		if (data) {
			setArtistResults(data)
		}
	}

	const loadComposers = async (input) => {
		setLoading(true)
		const url = SEARCH_ARTISTS_URL + input
		const { data } = await api.get(url)
		if (data) {
			setComposerResults(data)
		}
	}

	const loadGenre = async (input) => {
		setLoading(true)
		const url = SEARCH_GENRE_URL + input
		const { data } = await api.get(url)
		if (data) {
			setGenreResults(data)
			setLoading(false)
		}
	}

	return loading ? (
		<Loader isFromPersonalMM={true} />
	) : (
		<ScrollView style={{ backgroundColor: colors.boxes, height: height - 85, marginBottom: 20 }}>
			<View style={styles.profiler}>
				<View style={{flexDirection: 'row'}}>
					<Text style={styles.titleText}>Profil: </Text>
					<Text style={styles.profileNameText}>{profile.name}</Text>
				</View>
                <PrimaryButton
                    style={styles.profilerButton}
                    title="Skift profil"
                    onPress={() => {navigation.navigate('ProfileListScreen')}}
                />
            </View>
			<View style={{flexDirection: 'row'}}>
					<Text style={styles.subheaderText}>Favorit kunstnere, komponister, genrer or stilarter</Text>
			</View>
			{artistResults.Artists.length > 0 && (
				<ResultArtist
					artists={artistResults.MostRelated}
					navigation={navigation}
				/>
			)}
			{composerResults.Artists.length > 0 && (
				<ResultComposer
					composers={composerResults.MostRelated}
					navigation={navigation}
				/>
			)}
			{genreResults.Genre.length > 0 && (
				<ResultGenre
					genres={genreResults.Genre}
					navigation={navigation}
				/>
			)}
		</ScrollView>
	)
}
