import { useNavigation } from "@react-navigation/native"
import { Pressable, View } from "react-native"
import Svg, { G, Line, Polygon, Rect, Text, TSpan } from "react-native-svg"
import useAxios from "../../../Hooks/useAxios"

export default function SvgItem({ hasNextItem, hasPreviousItem, hasPreviousRow, hasNextRow, title,
    container_group, cancelModalCallback, sub_container, isRightLine = false, isLeftLine = false,
    totalNumberItems = 2, backgroundColor }) {


    const width = totalNumberItems > 3 ? String(58 * totalNumberItems) : String(45 * totalNumberItems)
    const x2LeftLine = String(60 * totalNumberItems)
    const x2RightLine = String(60 * totalNumberItems)

    let titles = title.split(' ')
    const navigation = useNavigation()
    const api = useAxios()

    return isLeftLine ? <Svg
        width={width}
        height="130"
    >
        {hasPreviousItem &&
            <G>
                <Line x1="0" y1="0" x2={x2LeftLine} y2="0" stroke={backgroundColor} strokeWidth="2" />
            </G>
        }
    </Svg >
        : <View>
            {isRightLine ?
                <Svg
                    width={width}
                    height="130"
                >
                    {hasNextItem &&
                        <G>
                            <Line x1="0" y1="0" x2={x2RightLine} y2="0" stroke={backgroundColor} strokeWidth="2" />
                        </G>
                    }
                </Svg>
                : (
                    <Pressable onPress={async () => {
                        if (!hasNextRow) {
                            navigation.navigate('PlaylistScreen', {
                                categoryId: container_group,
                                hasCopyPlaylistBtn: true,
                                sub_container: sub_container
                            })
                            cancelModalCallback()
                        }
                    }}>
                        <Svg
                            height="130"
                            width="130"
                        >
                            {hasPreviousItem &&
                                <G>
                                    <Line x1="0" y1="0" x2="55" y2="0" stroke={backgroundColor} strokeWidth="2" />
                                </G>
                            }
                            {hasPreviousRow &&
                                <G>
                                    <Line x1="53" y1="0" x2="53" y2="25" stroke={backgroundColor} strokeWidth="2" />
                                </G>
                            }
                            {hasNextItem &&
                                <G>
                                    <Line x1="53" y1="0" x2="140" y2="0" stroke={backgroundColor} strokeWidth="2" />
                                </G>
                            }
                            <Rect
                                width="80%"
                                height="70%"
                                fill={backgroundColor}
                                strokeWidth="0"
                                rx={20}
                                ry={20}
                                y={15}
                            />
                            {hasNextRow &&
                                <G>
                                    <Polygon
                                        points="20,60 20,80 50,70"
                                        fill={backgroundColor}
                                        strokeWidth="0"
                                        rotation="90"
                                        origin="29, 95"
                                    />

                                </G>
                            }
                            {/* <View style={{ width: 110 }}> */}
                            <Text
                                // x="20"
                                y="25"
                                textAnchor="start"
                                fontSize="13"
                                fill="white"
                            >
                                {
                                    titles.map((item, index) => (
                                        <TSpan x="10" dy="15" key={item + index}>
                                            {item}
                                        </TSpan>
                                    ))
                                }
                            </Text>
                            {/* </View> */}
                        </Svg>
                    </Pressable>
                )
            }
        </View>
}