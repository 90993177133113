import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { useState } from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { MenuDivider } from 'react-native-material-menu';
import {
    MenuOption
} from "react-native-popup-menu";
import { colors } from "../../../Helpers/variables";
import { FlatList } from 'react-native';

const ScrollViewMenu = ({ mainContainers, subContainersAllLevelsExceptCurrLevel,
    isThumbnailsSize, currentLevel, setSubContainerVisible, mainContainerName
}) => {
    const [isSubCategoriesMenuOpen, setIsSubCategoriesMenuOpen] = useState(false)
    const [selectedMainContainer, setSelectedMainContainer] = useState(mainContainers.length > 0 ?
        mainContainers[0] : undefined)
    const [subContainers, setSubContainers] = useState([])
    const navigation = useNavigation()
    const [prevSubContainer, setPrevSubContainer] = useState(undefined)
    const [currentLevelState, setCurrentLevelState] = useState(currentLevel)

    const MenuTriggerView = ({ itemMainContainer }) => {
        return (
            <View
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                    padding: 5
                }}
            >
                <Text
                    style={{
                        // marginTop: 5,
                        fontSize: isThumbnailsSize ? 16 : 25,
                        color: 'white',
                        marginLeft: 12,
                        marginRight: 12
                    }}
                    ellipsizeMode={'tail'}
                    numberOfLines={2}
                >
                    {itemMainContainer.sub_container_name}
                </Text>
                {
                    subContainersAllLevelsExceptCurrLevel.filter(x => x.main_sub_container === itemMainContainer.sub_container
                        && x.level === currentLevel + 1).length > 0 &&
                    <Ionicons name="arrow-forward-circle" size={15} color="white" />
                }
            </View>
        )
    }

    const MenuOptionView = ({ item, index }) => {
        return (
            <View>
                {
                    index === 0 &&
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Pressable style={{ flexDirection: 'row', marginLeft: 10, marginBottom: 10, marginTop: 4 }}
                            onPress={() => {
                                if (prevSubContainer) {
                                    setSelectedMainContainer(mainContainers.length > 0 ?
                                        mainContainers[0] : undefined)
                                    setSubContainers(subContainersAllLevelsExceptCurrLevel.filter(x => x.main_sub_container === prevSubContainer.main_sub_container
                                        && x.level === prevSubContainer.level))
                                    setPrevSubContainer(undefined)
                                    setCurrentLevelState(currentLevelState - 1)
                                }
                                else {
                                    setIsSubCategoriesMenuOpen(false)
                                    setPrevSubContainer(undefined)
                                }
                            }}>
                            <Ionicons name="arrow-undo-circle" size={18}
                                color={"white"}
                                style={{ marginTop: 5, marginRight: 5 }} />
                            <Text style={{ color: 'white', fontSize: 18, marginTop: 2, minWidth: 250 }}>
                                {selectedMainContainer.sub_container_name}
                            </Text>
                        </Pressable>
                        <Pressable style={{ flexDirection: 'row', marginLeft: 10, marginBottom: 10, marginTop: 4 }}
                            onPress={() => {
                                setSubContainerVisible(false)
                            }}>
                            <Ionicons name="close" size={18}
                                color={"white"}
                                style={{ marginTop: 5, marginRight: 5 }} />
                        </Pressable>
                    </View>

                }
                <Pressable
                    onPress={() => {
                        if (subContainersAllLevelsExceptCurrLevel.filter(x => x.main_sub_container === item.sub_container
                            && x.level === currentLevelState + 2 && x.main_sub_container === item.sub_container).length > 0) {
                            setPrevSubContainer(item)
                            setSelectedMainContainer(item)
                            setSubContainers(subContainersAllLevelsExceptCurrLevel.filter(x => x.main_sub_container === item.sub_container
                                && x.level === currentLevelState + 2))
                            setCurrentLevelState(currentLevelState + 1)
                        } else {
                            navigation.navigate('PlaylistScreen', {
                                categoryId: item.container_group,
                                hasCopyPlaylistBtn: true,
                                sub_container: item.sub_container,
                                categoryName: item.sub_container_name
                            })
                            setSubContainerVisible(false)
                        }


                    }}
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: 8
                    }}
                >
                    <Text style={{
                        // marginTop: 5,
                        fontSize: isThumbnailsSize ? 16 : 25,
                        color: 'white',
                        marginLeft: 12,
                        marginRight: 12
                    }}
                        ellipsizeMode={'tail'}
                        numberOfLines={2}>
                        {item.sub_container_name}
                    </Text>
                    {
                        subContainersAllLevelsExceptCurrLevel.filter(x => x.main_sub_container === item.sub_container
                            && x.level === currentLevelState + 2).length > 0 &&
                        <Ionicons name="arrow-forward-circle" size={15} color="white" />
                    }
                </Pressable>
                <MenuDivider color='gray' />
            </View >
        )
    }

    return (
        <ScrollView style={{ maxHeight: 400, backgroundColor: colors.primary }}>
            {
                !isSubCategoriesMenuOpen ?
                    (
                        <View>
                            {
                                mainContainers.map((itemMainContainer, index) => (
                                    <View key={itemMainContainer.id + 'djlsafj'}>
                                        {
                                            index === 0 &&
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Pressable style={{ flexDirection: 'row', marginLeft: 10, marginBottom: 10, marginTop: 4 }}
                                                    onPress={() => {
                                                        setSubContainerVisible(false)
                                                    }}>
                                                    <Ionicons name="arrow-undo-circle" size={18}
                                                        color={"white"}
                                                        style={{ marginTop: 5, marginRight: 5 }} />
                                                    <Text style={{ color: 'white', fontSize: 18, marginTop: 3, minWidth: 300 }}>
                                                        {mainContainerName}
                                                    </Text>
                                                </Pressable>
                                                <Pressable style={{ flexDirection: 'row', marginLeft: 10, marginBottom: 10, marginTop: 4 }}
                                                    onPress={() => {
                                                        setSubContainerVisible(false)
                                                    }}>
                                                    <Ionicons name="close" size={18}
                                                        color={"white"}
                                                        style={{ marginTop: 5, marginRight: 5 }} />
                                                </Pressable>
                                            </View>
                                        }
                                        <Pressable
                                            onPress={() => {
                                                if (subContainersAllLevelsExceptCurrLevel.filter(x => x.main_sub_container === itemMainContainer.sub_container
                                                    && x.level === currentLevel + 1).length > 0) {
                                                    setSelectedMainContainer(itemMainContainer)
                                                    setSubContainers(subContainersAllLevelsExceptCurrLevel.filter(x => x.main_sub_container === itemMainContainer.sub_container
                                                        && x.level === currentLevel + 1 && x.main_sub_container === itemMainContainer.sub_container))
                                                    setIsSubCategoriesMenuOpen(true)
                                                }
                                                else {
                                                    navigation.navigate('PlaylistScreen', {
                                                        categoryId: itemMainContainer.container_group,
                                                        hasCopyPlaylistBtn: true,
                                                        sub_container: itemMainContainer.sub_container,
                                                        categoryName: itemMainContainer.sub_container_name
                                                    })
                                                    setIsSubCategoriesMenuOpen(false)
                                                    setSubContainerVisible(false)
                                                }
                                            }}
                                        >
                                            <MenuTriggerView itemMainContainer={itemMainContainer} />
                                            <MenuDivider color='gray' />
                                        </Pressable>
                                    </View>
                                ))
                            }
                        </View>
                    )
                    :
                    (
                        <View style={{
                            backgroundColor: colors.primary
                        }}>
                            <FlatList
                                data={subContainers}
                                keyExtractor={(item) => item.id}
                                renderItem={({ item, index }) => (
                                    <MenuOptionView item={item} index={index} />
                                )}
                            />
                        </View>
                    )
            }
        </ScrollView>
    );

};

export default ScrollViewMenu;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.secondary,
        width: 330
    },
});