import { View, ActivityIndicator, Text } from 'react-native'
import { colors } from '../../Helpers/variables'

export default function Loader({ bgColor = colors.boxes, isFromPersonalMM = false }) {
	return (
		<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: bgColor, padding: 30 }}>
			{isFromPersonalMM && (
				<Text style={{color: 'white', fontSize: 18, marginBottom: 20}}>Vent venligst, mens resultaterne indlæses...</Text>
			)}
			<ActivityIndicator size="large" color={colors.accent} />
		</View>
	)
}
