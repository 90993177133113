import React, { useEffect, useState } from 'react'
import { Text, View, useWindowDimensions, StyleSheet, Image, Pressable } from 'react-native'
import Loader from '../../Components/Loader/Loader'
import { colors } from '../../Helpers/variables'
import { ScrollView } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { PrimaryButton } from '../../Components/Buttons/Button'
import { NotificationModal } from '../../Components/Modals/NotificationModal'

export default function PersonalMusicMenuScreen() {

	const route = useRoute()
	const { profile } = route.params

    const hasSelectedProfile = profile !== undefined && profile !== null;

	const [loading, setLoading] = useState(true)
    const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 400)
    const [boxWidth, setBoxWidth] = useState(isSmallScreen ? 150 : (isWideScreen ? 260 : 190));
    const navigation = useNavigation()

	const handleResize = () => {
		setIsWideScreen(width > 700)
		setIsSmallScreen(width <= 400)
        setBoxWidth(isSmallScreen ? 150 : (isWideScreen ? 260 : 190))
    }

	useEffect(() => {
		handleResize()
	}, [width])

    const styles = StyleSheet.create({
        mainBackground: {
            backgroundColor: colors.boxes,
            height: height - 85
        },
        profiler: {
            marginLeft: 10,
            justifyContent: hasSelectedProfile ? 'space-between' : 'flex-end',
            flexDirection: 'row',
            flex: 1,
            width: '100%',
        },
        row1: {
            marginTop: isSmallScreen ? '10%' : '5%',
            justifyContent: 'center',
            flexDirection: 'row',
            flex: 4,
            width: '100%',
            marginBottom: isSmallScreen ? -12 : (isWideScreen ? -3 : -9)
        },
        row2: {
            justifyContent: 'center',
            flexDirection: 'row',
            flex: 4,
            width: '100%',
        },
        profilerButton: {
            width: isWideScreen ? 90 : 70,
            minHeight: 30,
            margin: 20 
        },
        imageStyle: {
            width: boxWidth,
            height: boxWidth / 1.5
        },
        boxMargin: {
            marginRight: isSmallScreen ? -15 : (isWideScreen ? -25 : -19)
        },
		titleText: {
			color: 'white',
			fontSize: isWideScreen ? 25 : 20,
			margin: 10,
			fontWeight: 'bold'
		},
		profileNameText: {
			color: colors.accent,
			fontSize: isWideScreen ? 25 : 20,
			margin: 10,
			fontWeight: 'bold'
		}
    })
    
	useEffect(() => {
        setLoading(false)
	}, [])

	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const openNotifModal = (description) => {
		setNotifModal({
			visible: true,
			title: '',
			description: description,
			isDisappearing: false
		})
	}
	
	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

    const handleNavigation = (destinationScreen) => {
        if (profile === undefined || profile === null) {
            openNotifModal('Vælg venligst en profil.')
        } else {
            navigation.navigate(destinationScreen, {profile: profile})
        }
    }

	return loading ? (
		<Loader />
	) : (
		<ScrollView style={styles.mainBackground}>
            <View style={styles.profiler}>
				{hasSelectedProfile && (
                    <View style={{flexDirection: 'row'}}>
                        <Text style={styles.titleText}>Profil: </Text>
                        <Text style={styles.profileNameText}>{profile.name}</Text>
                    </View>
                )}
                <PrimaryButton
                    style={styles.profilerButton}
                    title="Profiler"
                    onPress={() => {navigation.navigate('ProfileListScreen')}}
                />
            </View>
            <View style={styles.row1}>
                <Pressable style={styles.boxMargin} onPress={() => {handleNavigation('MyYouthMusicScreen')}}>
                    <Image
                        source={require('../../assets/personalMM/box1.png')}
                        style={styles.imageStyle}
                        resizeMode={'contain'}
                    />
                </Pressable>
                <Pressable onPress={() => {handleNavigation('MyFavoriteMusicScreen')}}>
                    <Image
                        source={require('../../assets/personalMM/box2.png')}
                        style={styles.imageStyle}
                        resizeMode={'contain'}
                    />
                </Pressable>
            </View>
            <View style={styles.row2}>
                <Pressable style={styles.boxMargin} onPress={() => {handleNavigation('MyWellKnownCalmMusicScreen')}}>
                    <Image
                        source={require('../../assets/personalMM/box3.png')}
                        style={styles.imageStyle}
                        resizeMode={'contain'}
                    />
                </Pressable>
                <Pressable onPress={() => {handleNavigation('MyProfessionallySelectedMusicScreen')}}>
                    <Image
                        source={require('../../assets/personalMM/box4.png')}
                        style={styles.imageStyle}
                        resizeMode={'contain'}
                    />
                </Pressable>
            </View>
            
			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					setModalVisibleExternal={undefined}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}

		</ScrollView>
	)
}
