import React, { useEffect, useState } from 'react'

import { Alert, Platform, ScrollView, View } from 'react-native'
import * as Sentry from 'sentry-expo'
import Loader from '../../Components/Loader/Loader'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { playlistSortByName } from '../../Helpers/sortArray'
import { YEAR_TRACKS_URL, USER_PLAYLIST_URL, colors } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import PlaylistDetails from '../TracksScreen/components/PlaylistDetails'
import TrackList from '../TracksScreen/components/TrackList'
import { Text } from 'react-native'

export default function YearTracksScreen({ route, navigation }) {
	const [yearDetails, setYearDetails] = useState([])
	const { year } = route.params ? route.params : global.routeParams
	const [loading, setLoading] = useState(true)
	const api = useAxios()

	const [userPlaylists, setUserPlaylists] = useState([])
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const updateUserPlaylists = (id, name, description, visibility) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id: id, name: name, description: description, visibility: visibility })
		userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
		setUserPlaylists(userPlaylistsTemp)
	}

	useEffect(() => {
		async function boot() {
			let tracksList = []

			await api
				.get(USER_PLAYLIST_URL)
				.then(({ data }) => {
					data = playlistSortByName(data)
					setUserPlaylists(data)
				})
				.catch(error => {
					console.log(error)
					if (Platform.OS === 'web') Sentry.Browser.captureException(error)
					else Sentry.Native.captureException(error)
				})

			const { data } = await api.get(YEAR_TRACKS_URL + year)
			if (data.length > 0) {
				data.map(itemMain => {
					tracksList.push({
						track: itemMain,
						Photo: itemMain.Photo,
						title: itemMain.genre,
						id: itemMain.id
					})
				})
			}
			setYearDetails(tracksList)
			setLoading(false)
		}
		boot()
	}, [year])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.boxes, flex: 1 }}>
			<ScrollView>
				{yearDetails.length > 0 ? (
					<View>
						<View style={{ marginTop: 20 }}>
							<PlaylistDetails
								photo={yearDetails[0].Photo}
								title={yearDetails[0].title}
								tracks={yearDetails.length}
								tracksList={yearDetails}
								isRepeatAllTracks={true}
								isHideTherapist={true}
								artistName={''}
							/>
						</View>
						<TrackList
							photo={yearDetails[0].Photo}
							title={yearDetails[0].title}
							tracksList={yearDetails}
							key={yearDetails[0].id}
							navigation={navigation}
							userPlaylists={userPlaylists}
							updateUserPlaylists={updateUserPlaylists}
							openNotifModal={openNotifModal}
							closeNotifModal={closeNotifModal}
							isRepeatAllTracks={true}
						/>
					</View>
				) : (
					<View>
						<Text style={{color: 'white'}}>Ingen data</Text>
					</View>
				)}

				{notifModal.visible && (
					<NotificationModal
						title={notifModal.title}
						description={notifModal.description}
						visible={notifModal.visible}
						closeNotifModal={closeNotifModal}
						isDisappearing={notifModal.isDisappearing}
					/>
				)}
			</ScrollView>
		</View>
	)
}
