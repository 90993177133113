import { FlatList, Pressable, Text, View } from 'react-native'
import { colors } from '../../../Helpers/variables'
import Card from './components/Card'

export default function ResultGenre({ genres, navigation }) {
	console.log('genres', genres)
	return (
		<View>
			{genres && (
				<View>
					{genres.length === 0 && <Text style={{ color: 'white', marginLeft: 20, marginTop: 10 }}>Ingen data...</Text>}
				</View>
			)}
			{genres && (
				<View>
					{genres.length > 0 && (
						<View>
							<Text style={{ marginLeft: 20, fontSize: 20, color: 'white', marginTop: 10 }}>
								Genrer
							</Text>
							<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								<FlatList
									data={genres}
									renderItem={({ item, index }) => (
										<View
											style={{
												alignItems: 'center',
												justifyContent: 'center',
												height: 165,
												margin: 15
											}}
										>
											<Pressable onPress={() => navigation.navigate('GenreTracks', { genre: item })} key={index}>
												<Card image={item.Photo} title={item} />
											</Pressable>
										</View>
									)}
									keyExtractor={(item,index) => index}
									//if the user pulls from the top of the screen, log out to the console:
									onRefresh={() => console.log('refreshing')}
									//if set to true, the UI will show a loading indicator and trigger data.
									refreshing={false}
									horizontal={true}
								/>
							</View>
						</View>
					)}
				</View>
			)}
		</View>
	)
}
