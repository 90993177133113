/* ----------------------------- Library imports ---------------------------- */
import { useEffect, useState } from 'react'
import { FlatList, Platform, ScrollView, Text, View } from 'react-native'

/* --------------------------- Component Imports ---------------------------- */
import Loader from '../../Components/Loader/Loader'

/* ------------------------------ Hooks imports ----------------------------- */
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'

/* ------------------------------ Data helpers ------------------------------ */
import { addDataToContainersTable, retrieveDataFromContainersTable } from '../../Helpers/database/containersTable'
import { MAIN_CONTAINER_CATEGORY_BOX_SUBS_URL, SUB_CONTAINER_BOXES_URL, colors } from '../../Helpers/variables'

/* ---------------------------- Exception handler --------------------------- */
import RefreshButton from '../../Components/RefreshButton'
import { captureException } from '../../Helpers/sentryLog'
import { playlistSortByPosition } from '../../Helpers/sortArray'
import { isCachedDataValid } from '../../Helpers/timeConverter'
import ContainerCard from './components/ContainerCard'

export default function HomeScreen({ route, navigation, categoryIdParam, isThumbnailsSize = false }) {
	const api = useAxios()

	const { deviceType, isOffline, shouldReset, setShouldReset } = useLayout()

	const [containers, setContainer] = useState([])
	const [originalContainers, setOriginalContainers] = useState([])
	const [loading, setLoading] = useState(true)
	const [wasDataCached, setWasDataCached] = useState(false)

	const [shouldRefresh, setShouldRefresh] = useState(false)
	const [error, setError] = useState(false)

	const categoryId = categoryIdParam ? categoryIdParam :  route.params ? route.params.categoryId : global.routeParams.categoryId

	const getDataFromApi = async () => {
		// await new Promise(r => setTimeout(r, 1000))
		setLoading(true)
		const { data } = await api.get(MAIN_CONTAINER_CATEGORY_BOX_SUBS_URL + categoryId)
		if (data.length === 0) {
			setContainer(data)
			setOriginalContainers(data)
			addDataToContainersTable(data)
			setLoading(false)
		} else {
			await checkSubContainerApi(data)
		}
	}

	const checkSubContainerApi = async containers => {
		let newContainers = []
		containers.map(async item => {
			let url =
				SUB_CONTAINER_BOXES_URL + '?level=1' + '&container=' + item.container_group + '&category=' + item.category_group

			const { data } = await api.get(url)

			if (data.length > 0) item.hasNextSubContainer = true
			else item.hasNextSubContainer = false

			newContainers.push(item)

			if (newContainers.length === containers.length) {
				newContainers = playlistSortByPosition(newContainers)
				setContainer(newContainers)
				setOriginalContainers(newContainers)
				addDataToContainersTable(newContainers)
				setLoading(false)
			}
		})
	}

	useEffect(() => {
		if (shouldReset) {
			setError(false)
			// setLoading(true)
			async function boot() {
				try {
					// if (Platform.OS === 'web') {
					// 	await getDataFromApi()
					// 	return
					// }

					await getDataFromApi()
				} catch (error) {
					console.error(error)
					captureException(error)
					setError(true)
				} finally {
					// setLoading(false)
					setShouldReset(false)
				}
			}

			boot()
		}
	}, [shouldReset])

	useEffect(() => {
		setError(false)
		async function boot() {
			try {
				if (Platform.OS === 'web' || !isOffline) {
					await getDataFromApi()
					return
				}
				const cachedData = await retrieveDataFromContainersTable()
				if (!isCachedDataValid(cachedData)) {
					await getDataFromApi()
				} else {
					setWasDataCached(true)
					setContainer(cachedData)
					setOriginalContainers(cachedData)
				}
			} catch (error) {
				console.error(error)
				captureException(error)
				setError(true)
			}
			// finally {
			// 	// setLoading(false)
			// }
		}

		boot()
	}, [shouldRefresh])

	if (error) return <RefreshButton setShouldRefresh={setShouldRefresh} />
	return loading ? (
		<Loader />
	) : (
		<View
			style={{ backgroundColor: isThumbnailsSize ? '#101833' : colors.boxes, flex: 1, marginBottom: 10, padding: 7 }}
		>
			<ScrollView>
				<FlatList
					data={containers}
					renderItem={({ item }) => (
						<ContainerCard
							wasDataCached={wasDataCached}
							item={item}
							navigate={item.hasNextSubContainer ? navigation.push : navigation.navigate}
							deviceType={deviceType}
							screenName={item.hasNextSubContainer ? 'SubContainerScreen' : 'PlaylistScreen'}
							params={
								item.hasNextSubContainer
									? {
											categoryId: item.category_group,
											containerId: item.container_group,
											level: parseInt(1),
											categoryName: item.category_group_name
									  }
									: {
											categoryId: item.container_group,
											hasCopyPlaylistBtn: true,
											sub_container: item.id,
											categoryName: item.container_group_name
									  }
							}
							isThumbnailsSize={isThumbnailsSize}
							isSubContainer={item.hasNextSubContainer ? true : false}
						/>
					)}
					ListEmptyComponent={<Text style={{ color: 'white' }}>Ingen data...</Text>}
					keyExtractor={item => item.id}
					itemDimension={isThumbnailsSize ? 70 : 200}
					// additionalRowStyle={{ padding: 0 }}
					contentContainerStyle={{ paddingBottom: isThumbnailsSize ? 20 : 90 }}
					style={{ flex: 1 }}
				/>
			</ScrollView>
		</View>
	)
}
