import React, { useEffect, useState } from 'react'
import { Text, View } from 'react-native'
import { MenuDivider } from 'react-native-material-menu'
import { colors } from '../../Helpers/variables'
import Constants from 'expo-constants'
import { useWindowDimensions } from 'react-native'

export default function Footer() {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	return (
		<View
			style={{
				backgroundColor: colors.boxes,
				width: '100%',
				position: 'absolute',
				bottom: 0,
				left: 0,
				right: 0
			}}
		>
			<View
				style={{
					marginLeft: isWideScreen ? 80 : 10,
					marginRight: isWideScreen ? 80 : 10,
					backgroundColor: colors.boxes,
					width: '85%',
				}}
			>
				<MenuDivider color="gray" />
				<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: isWideScreen ? 30 : 10, marginBottom: 8 }}>
					<Text style={{ color: '#545C6D', fontSize: 16 }}>© MusicMind 2024</Text>
					<Text style={{ color: '#545C6D', fontSize: 16 }}>Version {Constants.expoConfig.version}</Text>
				</View>
			</View>
		</View>
	)
}
