/* ----------------------------- Library imports ---------------------------- */
import { useEffect, useState } from 'react'
import { Platform, ScrollView, StyleSheet, Text, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'

/* --------------------------- Component Imports ---------------------------- */

/* ------------------------------ Hooks imports ----------------------------- */
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'

/* ------------------------------ Data helpers ------------------------------ */
// import { addDataToSubContainersTable, retrieveDataFromSubContainersTable } from '../../Helpers/database/subContainersTable'
import { colors, MAIN_CONTAINER_CATEGORY_BOX_SUBS_TREE_URL } from '../../Helpers/variables'

/* ---------------------------- Exception handler --------------------------- */
import RefreshButton from '../../Components/RefreshButton'
import { captureException } from '../../Helpers/sentryLog'
import ContainerMenuTree from './components/ContainerMenuTree'

export default function SubContainerMenuTreeScreen({ categoryId,
	cancelModalCallback, style, isThumbnailsSize = false, navigate }) {
	const api = useAxios()

	const { deviceType, isOffline, shouldReset, setShouldReset } = useLayout()

	const [subContainers, setSubContainers] = useState([])
	const [loading, setLoading] = useState(true)
	const [wasDataCached, setWasDataCached] = useState(false)

	const [shouldRefresh, setShouldRefresh] = useState(false)
	const [error, setError] = useState(false)

	const getDataFromApi = async () => {
		setLoading(true)
		const { data } = await api.get(MAIN_CONTAINER_CATEGORY_BOX_SUBS_TREE_URL + categoryId)
		setSubContainers(data)
		setLoading(false)
	}

	useEffect(() => {
		if (shouldReset) {
			setError(false)
			setLoading(true)
			async function boot() {
				try {
					if (Platform.OS === 'web') {
						await getDataFromApi()
						return
					}

					await getDataFromApi()
				} catch (error) {
					console.error(error)
					captureException(error)
					setError(true)
				} finally {
					setLoading(false)
					setShouldReset(false)
				}
			}

			boot()
		}
	}, [shouldReset])

	useEffect(() => {
		setError(false)
		async function boot() {
			try {
				if (Platform.OS === 'web' || !isOffline) {
					await getDataFromApi()
					return
				}
				await getDataFromApi()
				// const cachedData = await retrieveDataFromSubContainersTable()
				// if (!isCachedDataValid(cachedData)) {
				// 	await getDataFromApi()
				// } else {
				// 	setWasDataCached(true)
				// 	setSubContainers(cachedData)
				// 	setOriginalSubContainers(cachedData)
				// }
			} catch (error) {
				console.error(error)
				captureException(error)
				setError(true)
			} finally {
				setLoading(false)
			}
		}

		boot()
	}, [shouldRefresh])

	if (error) return <RefreshButton setShouldRefresh={setShouldRefresh} />
	return (
		<View style={{ backgroundColor: isThumbnailsSize ? undefined : colors.boxes }}>
			<ScrollView>
				<FlatGrid
					data={subContainers.MainContainerBoxSubs}
					renderItem={({ item, index }) => (
						<ContainerMenuTree
							subContainers={subContainers.SubContainerBoxs.filter(x =>
								x.container_group === item.container_group
								&& x.category_group === item.category_group && x.level === 1)}
							subContainersAllLevelsExceptCurrLevel={subContainers.SubContainerBoxs.filter(x =>
								x.container_group === item.container_group
								&& x.category_group === item.category_group && x.level !== 1)}
							currentLevel={1}
							cancelModalCallback={cancelModalCallback}
							backgroundColor={style.backgroundColor}
							mainContainerGroup={item}
							isThumbnailsSize={isThumbnailsSize}
							deviceType={deviceType}
							navigate={navigate}

						/>
					)}
					ListEmptyComponent={<Text style={{ color: 'white' }}></Text>}
					keyExtractor={item => item.id}
					itemDimension={72}
					contentContainerStyle={{
						flexDirection: 'row',
					}}
					maxItemsPerRow={3}
				/>
			</ScrollView>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		backgroundColor: "#ecf0f1",
		padding: 8,
	},
});