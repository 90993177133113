import { StyleSheet } from 'react-native'
import { colors } from '../../Helpers/variables'

export const userStyles = StyleSheet.create({
	scrollView: {
		paddingTop: 20
	},
	userList: {
		flex: 2,
		marginLeft: 10
	},
	noData: {
		color: colors.accent,
		marginTop: 30,
		fontSize: 20
	},
	userRow: {
		// marginBottom: 20,
		flex: 1,
		alignSelf: 'stretch',
		flexDirection: 'row',
		alignItems: 'center'
	},
	// leftContainer: {
	// 	flex: 1,
	// 	alignSelf: 'stretch',
	// 	flexDirection: 'row',
	// 	justifyContent: 'flex-end'
	// },
	flex1Container: {
		flex: 2,
		alignSelf: 'stretch',
		marginTop: 20
	},
	rowContainer: {
		flex: 3,
		alignSelf: 'stretch',
		marginTop: 20
	},
	albumTitle: {
		color: 'white',
		fontSize: 12
	},
	menuView: {
		backgroundColor: colors.secondary,
		width: 200
	},
	menuTextView: {
		color: 'white',
		fontSize: 14
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		minWidth: 200,
		alignItems: 'center',
		justifyContent: 'center',
		height: 450
	},
	buttonContainer: {
		alignItems: 'center',
		width: '30%'
	}
})
