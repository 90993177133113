import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { Animated, PanResponder, Text, View, useWindowDimensions } from 'react-native';
import { Menu, MenuItem } from 'react-native-material-menu';
import { colors } from '../../../Helpers/variables';
import ScrollViewMenu from './ScrollViewMenu';

export default function ContainerMenuTree({ subContainers, subContainersAllLevelsExceptCurrLevel,
    cancelModalCallback, currentLevel, backgroundColor, mainContainerGroup, isThumbnailsSize,
    deviceType, navigate, isMenu = false }) {
    const boxWidth = isThumbnailsSize ? 75 : 240
    const { width, height } = useWindowDimensions();
    const [isWideScreen, setIsWideScreen] = useState(width >= 700)
    const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
    const [subContainerVisible, setSubContainerVisible] = useState(false)
    const navigation = useNavigation()
    const [titles, setTitles] = useState([])

    const animatedValue = new Animated.ValueXY();
    const value = { x: 0, y: 0 };
    animatedValue.addListener((value) => (value = value));
    const panResponder = PanResponder.create({
        onMoveShouldSetResponderCapture: () => true, //Tell iOS that we are allowing the movement
        onMoveShouldSetPanResponderCapture: () => true, // Same here, tell iOS that we allow dragging
        onPanResponderGrant: (e, gestureState) => {
            animatedValue.setOffset({ x: value.x, y: value.y });
            animatedValue.setValue({ x: 0, y: 0 });
        },
        onPanResponderMove: Animated.event([
            null,
            { dx: animatedValue.x, dy: animatedValue.y },
        ]), // Creates a function to handle the movement and set offsets
        onPanResponderRelease: () => {
            animatedValue.flattenOffset(); // Flatten the offset so it resets the default positioning
        },
    });

    useEffect(() => {
        handleResize()
    }, [width])

    const handleResize = () => {
        setIsWideScreen(width >= 700)
        setIsSmallScreen(width <= 320)
    }

    useEffect(() => {
        let titlesTemp = mainContainerGroup.container_group_name.split(/(?<=^\S+)\s/)
        let titlesNew = []
        if (titlesTemp.length > 0) {
            titlesNew.push(titlesTemp[0])
            if (titlesTemp.length > 1) {
                if (titlesTemp[1].length > 10) {
                    let temp = titlesTemp[1].split(' ')
                    let tempText = ''
                    temp.map((item) => {
                        if (item.length > 6) {
                            titlesNew.push(item)
                        } else {
                            if (tempText === '') {
                                tempText = item
                            } else {
                                tempText = `${tempText} ${item}`
                            }

                            if (tempText.length > 6) {
                                titlesNew.push(tempText)
                            }
                        }
                    })
                }
                else {
                    titlesNew.push(titlesTemp[1])
                }
            }
        }
        setTitles(titlesNew)
    }, [])

    const MainCategoryMenuItemView = () => {
        return (
            <MenuItem
                style={{
                    width: boxWidth,
                    height: 50,
                    backgroundColor: colors.boxes
                }}
                onPress={() => {
                    if (subContainers.length > 0)
                        setSubContainerVisible(true)
                    else {
                        setSubContainerVisible(false)
                        navigation.navigate('PlaylistScreen', {
                            categoryId: mainContainerGroup.container_group,
                            hasCopyPlaylistBtn: true,
                            sub_container: undefined,
                            categoryName: mainContainerGroup.container_group_name
                        })
                    }
                }}
                key={'menuitemsdfjaosjfd' + mainContainerGroup.id}
            >
                <View
                    style={{
                        width: boxWidth,
                        height: 50,
                        borderRadius: 10,
                        backgroundColor: backgroundColor,
                        marginLeft: -15,
                        paddingRight: 3
                    }}
                    key={'menuitemsdfjaosjfdddd' + mainContainerGroup.id}
                >
                    {/* <View style={{ justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'row' }}>
                        <Ionicons name="arrow-forward-circle" size={16}
                            color={subContainers.length > 0 ? "white" : backgroundColor}
                            key={'bbbbbbbbbbbbbbbbbbb' + mainContainerGroup.id}
                        />
                    </View> */}
                    <View
                        style={{
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            marginHorizontal: 10,
                            marginTop: 2,
                        }}
                    >
                        {
                            titles.map((item) => (
                                <Text style={{
                                    fontSize: 12,
                                    color: 'white',
                                }}
                                    key={item}
                                >
                                    {item}
                                </Text>
                            ))
                        }
                    </View>
                </View>
            </MenuItem>
        )
    }


    return (
        <View key={'asjfdlasjfdviewsfd' + mainContainerGroup.id}
            style={{
                backgroundColor: colors.primary,
                fontSize: 16,
                minWidth: 300,
                flex: 1,
            }}
        >
            <Menu
                key={'subcontainermenu' + mainContainerGroup.id}
                visible={subContainerVisible}
                anchor={
                    <View>{isMenu ?
                        (<Pressable onPress={toggleSidebar} style={{ marginTop: 10 }}>
                            <Ionicons name={'ios-menu'} size={30} color={'#ffffff'} />
                        </Pressable>)
                        :
                        (<MainCategoryMenuItemView key={'asjfdlasjfd' + mainContainerGroup.id} />)
                    }</View>
                }
                style={{
                    backgroundColor: 'transparent',
                }}
            >

                <Animated.View
                    style={[
                        {
                            transform: [
                                { translateX: animatedValue.x },
                                { translateY: animatedValue.y },
                            ]
                        },

                    ]}
                    {...panResponder.panHandlers}
                >
                    {/* // <SubMenuLevel1ItemView item={item} index={index} key={'asjfdlasjfdkdkdkd' + item.id} /> */}
                    <ScrollViewMenu
                        mainContainers={subContainers}
                        subContainersAllLevelsExceptCurrLevel={subContainersAllLevelsExceptCurrLevel}
                        isThumbnailsSize={isThumbnailsSize}
                        currentLevel={currentLevel}
                        setSubContainerVisible={setSubContainerVisible}
                        mainContainerName={mainContainerGroup.container_group_name}
                    />
                </Animated.View >
            </Menu>
        </View>
    )
}
