/* ----------------------------- Library imports ---------------------------- */
import { useEffect, useState } from 'react'
import { Platform, Pressable, ScrollView, Text, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'

/* --------------------------- Component Imports ---------------------------- */
import Loader from '../../Components/Loader/Loader'
import { Heading } from '../../Components/Typography/Typography'
import CategoryCard from './components/CategoryCard'

/* ------------------------------ Hooks imports ----------------------------- */
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'

/* ------------------------------ Data helpers ------------------------------ */
import { addDataToCategoriesTable, retrieveDataFromCategoriesTable } from '../../Helpers/database/categoriesTable'
import { MAIN_CONTAINER_CATEGORY_BOXES_URL, MAIN_CONTAINER_URL, colors } from '../../Helpers/variables'

/* ---------------------------- Exception handler --------------------------- */
import { ResetButton } from '../../Components/Buttons/Button'
import RefreshButton from '../../Components/RefreshButton'
import { captureException } from '../../Helpers/sentryLog'
import { isCachedDataValid } from '../../Helpers/timeConverter'
import CategoryMainDesign from './components/CategoryMainDesign'
import Footer from '../../Components/Layouts/Footer'

export default function CategoryScreen({ navigation }) {
	const api = useAxios()

	const { deviceType, isOffline, shouldReset, setShouldReset } = useLayout()

	const [categories, setCategory] = useState([])
	const [originalCategories, setOriginalCategories] = useState([])
	const [loading, setLoading] = useState(true)
	const [wasDataCached, setWasDataCached] = useState(false)

	const [shouldRefresh, setShouldRefresh] = useState(false)
	const [error, setError] = useState(false)

	const getDataFromApi = async () => {
		// await new Promise(r => setTimeout(r, 1000))
		const { data } = await api.get(MAIN_CONTAINER_CATEGORY_BOXES_URL)
		setCategory(data)
		setOriginalCategories(data)
		addDataToCategoriesTable(data)
	}

	useEffect(() => {
		if (shouldReset) {
			setError(false)
			setLoading(true)
			async function boot() {
				try {
					if (Platform.OS === 'web') {
						await getDataFromApi()
						return
					}

					await getDataFromApi()
				} catch (error) {
					console.error(error)
					captureException(error)
					setError(true)
				} finally {
					setLoading(false)
					setShouldReset(false)
				}
			}

			boot()
		}
	}, [shouldReset])

	useEffect(() => {
		setError(false)
		async function boot() {
			try {
				if (Platform.OS === 'web' || !isOffline) {
					await getDataFromApi()
					return
				}
				const cachedData = await retrieveDataFromCategoriesTable()
				if (!isCachedDataValid(cachedData)) {
					await getDataFromApi()
				} else {
					setWasDataCached(true)
					setCategory(cachedData)
					setOriginalCategories(cachedData)
				}
			} catch (error) {
				console.error(error)
				captureException(error)
				setError(true)
			} finally {
				setLoading(false)
			}
		}

		boot()
	}, [shouldRefresh])

	if (error) return <RefreshButton setShouldRefresh={setShouldRefresh} />
	return loading ? (
		<Loader />
	) : (
		// <View >
		<View style={{ backgroundColor: colors.boxes, height: '100%' }}>
			<ScrollView>
				<CategoryMainDesign
					items={categories}
					wasDataCached={wasDataCached}
					navigate={navigation.navigate}
					deviceType={deviceType}
				/>
			</ScrollView>
		</View>
		// </View>
	)
}
