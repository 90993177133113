import { useEffect, useState } from 'react'
import { Platform, Modal, StyleSheet, Text, View, useWindowDimensions, FlatList, ScrollView, Pressable, Image } from 'react-native'
import { PrimaryButton, SecondaryButton } from '../Buttons/Button'
import { colors, CREATE_PLAYLIST_URL, PLAYLIST_TRACKS_URL } from '../../Helpers/variables'
import { Input } from '../Forms/Input'
import { CheckboxTicker } from '../Forms/CheckboxTicker'
import axios from 'axios'
import useTokens from '../../Hooks/useTokens'
import { NotificationModal } from '../../Components/Modals/NotificationModal'

export const ImportTracksModal = ({
	importObject,
	existingTracks
}) => {
	const {width} = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [loading, setLoading] = useState(false)
	const { getTokens } = useTokens()
	const [updatedImportObject, setUpdatedImportObject] = useState(importObject)
	const [updatedExistingTracks, setUpdatedExistingTracks] = useState(existingTracks)
	const [selectedTracks, setSelectedTracks] = useState(existingTracks)
	const [isPlaylistCreated, setIsPlaylistCreated] = useState(false)
	const [addedCount, setAddedCount] = useState(0)
	const [existingCount, setExistingCount] = useState(0)
	const [failedCount, setFailedCount] = useState(0)
	const [tracksAddedMesage, setTracksAddedMesage] = useState('')
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const handleResize = () => {
		setIsWideScreen(width > 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		console.log('importObject', importObject)
		handleResize()
	}, [width])

	useEffect(() => {
		checkSelectedTracks()
	}, [updatedExistingTracks])

    const modalStyle = StyleSheet.create({
		centeredView: {
			flex: 1,
			alignItems: 'center'
		},
		modalView: {
			backgroundColor: colors.secondary,
			borderRadius: 20,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			width: isWideScreen ? '50%' : '30%',
			height: isWideScreen ? '90%' : '40%',
		},
		label: {
			color: 'white',
			fontSize: isWideScreen ? 20 : 18,
			marginLeft: isWideScreen ? 20 : 0
		},
		sublabel: {
			color: 'white',
			fontSize: isWideScreen ? 16 : 14,
			marginTop: 10,
		},
		innerLabel: {
			fontStyle: 'italic',
			color: 'white',
			fontSize: isWideScreen ? 12 : 10,
			marginLeft: '10%',
			marginTop: 10,
		},
		errorMessage: {
			color: 'red',
			fontSize: isWideScreen ? 16 : 14,
			marginLeft: '10%',
		},
		selectedIcon: {
			position: 'absolute',
			width: 30,
			height: 30,
			zIndex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 30,
			backgroundColor: colors.accent,
			borderRadius: 60
		},
		trackListScrollView: {
			flexGrow: 1,
			borderColor: 'gray',
			borderRadius: 5,
			borderWidth: 2,
			minHeight: '30%',
			marginLeft: '10%',
			flexDirection: 'row', 
			width: isWideScreen ? '80%' : '80%', 
			marginBottom: 15, 
			marginTop: 5, 
			alignItems: 'center', 
		}
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (description) => {
		setNotifModal({
			visible: true,
			title: '',
			description: description,
			isDisappearing: false
		})
	}

	const onChangeCheckedStatusExisting = (objItem) => {
		let tempExisting= [...updatedExistingTracks]
		let itemIndex = tempExisting.findIndex(x => x.id === objItem.id)
		let item = tempExisting[itemIndex]
		item.isChecked = !objItem.isChecked
		tempExisting[itemIndex] = item
		setUpdatedExistingTracks(tempExisting)
	}

	const checkSelectedTracks = () => {
		let tempExisting= [...updatedExistingTracks]
		const selectedTracks = tempExisting.filter(x => x.isChecked)
		setSelectedTracks(selectedTracks)
	}

	const handleSavingPlaylist = async () => {
		if (selectedTracks.length > 0) {
			setLoading(true)

			if (importObject.importOption === 0) {
				await createNewPlaylist()
			} else {
				await addTracks()
			}
		}
	}

	const createNewPlaylist = async () => {
		const playlistName = importObject.playlistName
		
		const formData = new FormData()
		formData.append('name', playlistName)
		formData.append('description', playlistName)
		formData.append('visibility', '1')

		const { access } = await getTokens()
		const {data } = axios
			.post(CREATE_PLAYLIST_URL, formData, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				if (response.status === 201) {
					let newImportObject = updatedImportObject
					newImportObject.destinationPlaylist = response.data.id
					setUpdatedImportObject(newImportObject)
					addTracks()
				}
			})
			.catch(err => {
				alert({ error: true, message: 'Noget gik galt' })
				console.log('createNewPlaylist: ' + err)
				setLoading(false)
			})
			.finally(() => {
			})

		return data
	}

	const addTracks = async () => {
		selectedTracks.map(async track => {
			await addToPlaylist(updatedImportObject.destinationPlaylist, track.trackId)
		})
		
		let result = ''
		if (addedCount > 0) {
			result = 'Gemte ' + ctrAdded + ' af ' + selectedTracks.length + ' valgte Numre. '
		}
		
		if (existingCount > 0) {
			result += ctrExisting + ' Numre findes allerede. '
		}
		
		if (failedCount > 0) {
			result += 'Kunne ikke gemme ' + ctrFailed + ' Numre. Prøv venligst igen eller klik på Næste for at fortsætte.'
		} else {
			result += 'Klik på Næste for at fortsætte.'
		}

		openNotifModal('Playliste gemt med succes. Klik på Næste for at fortsætte.')
		setTracksAddedMesage(result)
	}

	const addToPlaylist = async (playlistId, trackId) => {
		const defaultPosition = 0
		const { access } = await getTokens()
		axios
			.post(
				PLAYLIST_TRACKS_URL + playlistId,
				{ track: trackId, position: defaultPosition },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					let count = addedCount
					setAddedCount(count++)
				} else {
					let count = failedCount
					setFailedCount(count++)
				}

				setLoading(false)
			})
			.catch(err => {
				console.log('addToPlaylist: ' + err)
				if (err.message === 'Request failed with status code 400') {
					let count = existingCount
					setExistingCount(count++)
				} else {
					let count = failedCount
					setFailedCount(count++)
				}

				setLoading(false)
			})
	}

	const TracksChecklist = ({ item, isChecked, handleCheckboxClick }) => {
		return (
			<CheckboxTicker
				isChecked={isChecked}
				label={item.title.trim() + ' by ' + item.artists.trim()}
				handleCheckboxClick={handleCheckboxClick}
				item={item}
				style={{ fontSize: 15 }}
			/>
		)
	}

	return (
		<View>
			<View style={{ flexDirection: 'row', alignItems: 'center', width: '80%', marginLeft: '10%', marginBottom: 10 }}>
			{updatedExistingTracks.length > 0 ? (
				<Text style={modalStyle.sublabel}>Følgende {updatedExistingTracks.length} numre findes i vores database og ved 
					at klikke på "Gem spilleliste" knappen bliver numrene gemt på spillelisten" Hvis der er et eller flere numre du ønsker fjernet, 
					skal du blot fjerne fluebenet ud fra det/dem. 
					Klik på "Gem spilleliste" og derefter på "næste knappen" for at komme videre i processen.
				</Text>
			) : (
				<Text style={modalStyle.sublabel}>Ingen Eksisterende numre er hentet. Klik "Næste" for at fortsøtte.</Text>
			)}
			</View>
			<View style={modalStyle.trackListScrollView}>
				<View style={{ flexDirection: 'row', width:'100%', marginBottom: 15, marginTop: 5, alignItems: 'center' }}>
					<FlatList
						data={existingTracks}
						renderItem={track =>
							<View style={{ marginRight: isWideScreen ? 30 : 0 }} >
								<TracksChecklist item={track.item} isChecked={true} handleCheckboxClick={onChangeCheckedStatusExisting}/>
							</View>
						}
						ListEmptyComponent={
							<Text style={{ color: 'white' }}>Ingen data...</Text>
						}
						keyExtractor={track => track.id}
					/>
				</View>
			</View>
			<View style={{ flexDirection: 'row', width: '100%', marginLeft: '10%', marginBottom: 5, alignItems: 'center', }}>
				<PrimaryButton
					title='Gem Spilleliste'
					onPress={() => handleSavingPlaylist()}
					loading={loading}
					style={{ 
						width: isWideScreen ? '80%' : '80%', 
						marginRight: 2, 
						backgroundColor: selectedTracks.length > 0 ? colors.accent : 'gray' 
					}}
					isDisabled={selectedTracks.length === 0}
				/>
			</View>
			{tracksAddedMesage !== '' && (
				<View style={{ flexDirection: 'row', width: '100%', marginLeft: '10%', alignItems: 'center', }}>
					<Text style={[modalStyle.sublabel, { marginTop: 0 }]}>{tracksAddedMesage}</Text>
				</View>
			)}
			
			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					setModalVisibleExternal={undefined}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}
		</View>
	)
}
