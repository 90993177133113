import { useState } from 'react'
import { useWindowDimensions, View } from 'react-native'

import Loader from '../../Components/Loader/Loader'

import { SceneMap, TabBar, TabView } from 'react-native-tab-view'
import { colors } from '../../Helpers/variables'
import PlaylistToUser from './PlaylistToUser'
import UserToPlaylist from './UserToPlaylist'

const renderScene = SceneMap({
	first: PlaylistToUser,
	second: UserToPlaylist
})

export default function AssignPlaylistUserScreen() {
	const { height, width } = useWindowDimensions()
	const [loading, setLoading] = useState(false)
	const [index, setIndex] = useState(0)
	const [routes] = useState([
		{ key: 'first', title: 'Tildel spillelister til brugerene' },
		{ key: 'second', title: 'Tildel brugeren til spillelisten' }
	])

	const renderTabBar = props => (
		<TabBar {...props} indicatorStyle={{ backgroundColor: 'white' }} style={{ backgroundColor: colors.secondary }} />
	)

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.boxes, height: '99.5%', magrginRight: 5 }}>
			<TabView
				navigationState={{ index, routes }}
				renderScene={renderScene}
				onIndexChange={setIndex}
				initialLayout={{ width: width }}
				renderTabBar={renderTabBar}
			/>
		</View>
	)
}
