import { useEffect, useState } from 'react'
import { Image, ImageBackground, Platform, Pressable, View } from 'react-native'
import { InfoButton } from '../../../Components/Buttons/Button'
import { Popup } from '../../../Components/Playlists/Popup'
import useFiles from '../../../Hooks/useFiles'
import { Text } from 'react-native'

export default function ContainerCard({
	item,
	wasDataCached,
	navigate,
	deviceType,
	screenName,
	params,
	isThumbnailsSize,
	isSubContainer = false
}) {
	// console.log(JSON.stringify(screenName))
	const width = isThumbnailsSize ? 70 : deviceType === 1 ? 240 : 190

	const { getPhoto, isImage } = useFiles()
	let photo = item.container_group_photo ? item.container_group_photo : item.sub_container_photo
	const [image, setImage] = useState(photo)
	const fallback = require('../../../assets/fallback_cat.png')
	const [modalVisible, setModalVisible] = useState(false)
	let description = item.container_group_description ? item.container_group_description : item.sub_container_description
	let name = item.container_group_name ? item.container_group_name : item.sub_container_name
	useEffect(() => {
		if (Platform.OS !== 'web' && wasDataCached) {
			if (isImage(photo)) {
				setImage(getPhoto(photo))
			} else {
				setImage(null)
			}
		} else {
			setImage(photo)
		}
	}, [])

	return (
		<Pressable onPress={() => navigate(screenName, params)}>
			<View
				style={{
					width: width,
					borderRadius: 10,
					marginBottom: 15,
					marginHorizontal: isThumbnailsSize ? 5 : 15,
					// overflow: 'hidden',
					flexDirection: isThumbnailsSize ? 'row' : undefined
				}}
			>
				<ImageBackground
					style={{ width: width, height: width, borderRadius: 10 }}
					resizeMode="cover"
					onError={() => console.log('Error loading image...')}
					source={image ? { uri: image } : fallback}
					imageStyle={{ borderRadius: 10 }}
				>
					{/* {description && <InfoButton size={20} fontSize={16} setModalVisible={setModalVisible} />} */}
					<View>
						{description && (
							<View
								style={{
									justifyContent: 'flex-end',
									alignItems: 'flex-end',
									marginTop: -10
								}}
							>
								<Pressable
									style={{
										width: isThumbnailsSize ? 23 : 30,
										height: isThumbnailsSize ? 23 : 30
									}}
									onPress={() => {
										setModalVisible(true)
									}}
								>
									<Image
										source={require('../../../assets/icon-png/info.png')}
										style={{
											width: isThumbnailsSize ? 23 : 30,
											height: isThumbnailsSize ? 23 : 30,
											marginTop: 10,
											marginRight: 10
										}}
										resizeMode={'stretch'}
									/>
								</Pressable>
							</View>
						)}
						{isSubContainer && !isThumbnailsSize && (
							<View
								style={{
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: 10,
									height: width - 60
								}}
							>
								<Text
									style={{
										marginLeft: 15,
										marginRight: 15,
										marginTop: 5,
										fontSize: 25,
										color: 'white',
										textAlign: 'center'
									}}
								>
									{name}
								</Text>
							</View>
						)}
					</View>
				</ImageBackground>
				{isThumbnailsSize && (
					<View
						style={{
							// justifyContent: 'center',
							// alignItems: 'center',
							// marginTop: 10,
							height: 70,
							width: 90
						}}
					>
						<Text
							style={{
								marginLeft: 10,
								// marginRight: 15,
								// marginTop: 5,
								fontSize: 18,
								color: 'white'
								// textAlign: 'center'
							}}
							ellipsizeMode={'tail'}
							numberOfLines={3}
						>
							{name}
						</Text>
					</View>
				)}
			</View>
			{modalVisible && (
				<Popup modalVisible={modalVisible} setModalVisible={setModalVisible} description={description} />
			)}
		</Pressable>
	)
}
