import { useEffect, useState } from 'react'
import { FlatList, ScrollView, Text, useWindowDimensions, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'
import SvgItem from './SvgItem';

export default function ContainerTree({ subContainers, subContainersAllLevelsExceptCurrLevel,
    cancelModalCallback, currentLevel, backgroundColor }) {
    const { width, height } = useWindowDimensions();
    const [isWideScreen, setIsWideScreen] = useState(width >= 700)
    const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

    useEffect(() => {
        handleResize()
    }, [width])

    const handleResize = () => {
        setIsWideScreen(width >= 700)
        setIsSmallScreen(width <= 320)
    }

    useEffect(() => {
        // if (items.length > 0) {
        //     if (Platform.OS !== 'web' && wasDataCached) {
        //         if (isImage(items[0].Photo)) {
        //             setImageBg(getPhoto(items[0].Photo))
        //         } else {
        //             setImageBg(null)
        //         }
        //     } else {
        //         setImageBg(items[0].Photo)
        //     }
        // }
    }, [])

    const ContentTree = ({ item, index }) => {
        return (
            <View style={{ flex: 1, alignItems: 'center' }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {
                        subContainersAllLevelsExceptCurrLevel.filter(x => x.container_group === item.container_group
                            && x.category_group === item.category_group && x.main_sub_container === item.sub_container
                            && x.level === currentLevel + 1).length > 1 &&
                        <SvgItem title={item.sub_container_name}
                            hasPreviousItem={index > 0 ? true : false}
                            key={item.sub_container_name + index + 'SDLFAJSDLF'}
                            isLeftLine={true}
                            totalNumberItems={(currentLevel === 1 && subContainersAllLevelsExceptCurrLevel.filter(x =>
                            (x.container_group === item.container_group
                                && x.category_group === item.category_group
                                && x.level === 3)).length > 10)
                                ? subContainersAllLevelsExceptCurrLevel.filter(x => x.container_group === item.container_group
                                    && x.category_group === item.category_group
                                    && x.level > currentLevel).length
                                : subContainersAllLevelsExceptCurrLevel.filter(x => x.container_group === item.container_group
                                    && x.category_group === item.category_group && x.main_sub_container === item.sub_container
                                    && x.level > currentLevel).length}
                            backgroundColor={backgroundColor}
                        />

                    }
                    <SvgItem title={item.sub_container_name}
                        hasNextItem={index < subContainers.length - 1 ? true : false}
                        hasPreviousItem={index > 0 ? true : false}
                        hasPreviousRow={true}
                        hasNextRow={subContainersAllLevelsExceptCurrLevel.filter(x => x.container_group === item.container_group
                            && x.category_group === item.category_group && x.main_sub_container === item.sub_container
                            && x.level === currentLevel + 1).length > 0 ? true : false}
                        key={item.sub_container_name + index}
                        container_group={item.container_group}
                        cancelModalCallback={cancelModalCallback}
                        sub_container={item.id}
                        backgroundColor={backgroundColor}
                    />
                    {
                        subContainersAllLevelsExceptCurrLevel.filter(x => x.container_group === item.container_group
                            && x.category_group === item.category_group && x.main_sub_container === item.sub_container
                            && x.level === currentLevel + 1).length > 1 &&
                        <SvgItem title={item.sub_container_name}
                            hasNextItem={index < subContainers.length - 1 ? true : false}
                            hasPreviousItem={index > 0 ? true : false}
                            key={item.sub_container_name + index + 'SDLFAJSDLFDD'}
                            isRightLine={true}
                            totalNumberItems={(currentLevel === 1 && subContainersAllLevelsExceptCurrLevel.filter(x =>
                            (x.container_group === item.container_group
                                && x.category_group === item.category_group
                                && x.level === 3)).length > 10)
                                ? subContainersAllLevelsExceptCurrLevel.filter(x => x.container_group === item.container_group
                                    && x.category_group === item.category_group
                                    && x.level > currentLevel).length
                                : subContainersAllLevelsExceptCurrLevel.filter(x => x.container_group === item.container_group
                                    && x.category_group === item.category_group && x.main_sub_container === item.sub_container
                                    && x.level > currentLevel).length}
                            backgroundColor={backgroundColor}
                        />

                    }
                </View>
                <View style={{ marginTop: -15, marginLeft: 2 }}>
                    {
                        subContainersAllLevelsExceptCurrLevel.filter(x => x.container_group === item.container_group
                            && x.category_group === item.category_group && x.main_sub_container === item.sub_container
                            && x.level === currentLevel + 1).length > 0 ?
                            <ContainerTree
                                subContainers={subContainersAllLevelsExceptCurrLevel.filter(x => x.container_group === item.container_group
                                    && x.category_group === item.category_group && x.main_sub_container === item.sub_container
                                    && x.level === currentLevel + 1)}
                                subContainersAllLevelsExceptCurrLevel={
                                    subContainersAllLevelsExceptCurrLevel.filter(x => x.level !== currentLevel + 1)}
                                currentLevel={currentLevel + 1}
                                cancelModalCallback={cancelModalCallback}
                                backgroundColor={backgroundColor}
                            />
                            : undefined
                    }
                </View>
            </View>
        )
    }

    return (
        <FlatList
            data={subContainers}
            renderItem={({ item, index }) => (
                <View >
                    <ContentTree item={item} index={index} />
                </View>
            )}
            ListEmptyComponent={<Text style={{ color: 'white' }}></Text>}
            keyExtractor={item => item.id}
            contentContainerStyle={{
                flex: 1, flexDirection: 'row',
            }}
            style={{
                flex: 1, flexDirection: 'row'
            }}
            horizontal={true}
            itemDimension={100}
        // style={{ flex: 1 }}
        />
    )
}
