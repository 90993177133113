import React, { useEffect, useState } from 'react'
import { Text, View, useWindowDimensions, StyleSheet } from 'react-native'
import { Input, TextArea } from '../../Components/Forms/Input'
import Loader from '../../Components/Loader/Loader'
import { colors } from '../../Helpers/variables'
import { ScrollView } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { PrimaryButton } from '../../Components/Buttons/Button'
import { NotificationModal } from '../../Components/Modals/NotificationModal'

export default function ProfileInformationScreen() {
	const [loading, setLoading] = useState(true)
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 500)

	const musicInfo = "(genrer/stilarter/kunstnere/bestemte numre)"
	const musicQuestion = "(genrer/stilarter/kunstnere/bestemte numre)"

	const emptyLabel = 'Ingen spillelister'
	const navigation = useNavigation()
	const route = useRoute()
	const { isNew = false, selectedItem = undefined } = route.params

	const [name, setName] = useState(selectedItem !== undefined ? selectedItem.name : '')
	const [birthYear, setBirthYear] = useState(selectedItem !== undefined ? selectedItem.birthYear : '')
	const [childhoodResidence, setChildhoodResidence] = useState(selectedItem !== undefined ? selectedItem.childhoodResidence : '')
	const [unwantedMusic, setUnwantedMusic] = useState(selectedItem !== undefined ? selectedItem.unwantedMusic : '')
	const [musicMemories, setMusicMemories] = useState(selectedItem !== undefined ? selectedItem.musicMemories : '')
	const [childhoodMusic, setChildhoodMusic] = useState(selectedItem !== undefined ? selectedItem.screen1.childhoodMusic : '')
	const [childhoodHomeMusic, setChildhoodHomeMusic] = useState(selectedItem !== undefined ? selectedItem.screen1.childhoodHomeMusic : '')

	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	//TO-DO 11-21
	/*
	
	Rewatch video

	Menu
	-profile label once profile is selected from list
		-when does selecting happen?
	-screen per category
		-do categories vary per profile?
		-is this customizable
	
	*/
	const [profile, setProfile] = useState(
		{
			id: '',
			name: '',
			birthYear: '',
			childhoodResidence: '',
			unwantedMusic: '',
			musicMemories: '',
			screen1: {
				childhoodMusic: '',
				childhoodHomeMusic: '',
			},
			screen2: {
				childhoodMusic: '',
				childhoodHomeMusic: '',
			},
			screen3: {
				childhoodMusic: '',
				childhoodHomeMusic: '',
			},
			screen4: {
				childhoodMusic: '',
				childhoodHomeMusic: '',
			}
		}
	)

	const handleResize = () => {
		setIsWideScreen(width > 700)
		setIsSmallScreen(width <= 500)
	}

	useEffect(() => {
		handleResize()
	}, [width])
	
	useEffect(() => {
		setProfile(selectedItem)
		setLoading(false)
	}, [])

	const styles = StyleSheet.create({
        profiler: {
            flex: 1,
            width: '100%',
			borderColor: 'gray',
			borderBottomWidth: 1,
			marginBottom:  isSmallScreen ? 2 : 5,
			paddingBottom: isSmallScreen ? 0 : 3,
			maxHeight: isSmallScreen ? 140 : 160
        },
		container: {
			flex: 1,
			color: 'white',
			marginTop: isWideScreen ? 0 : 2,
            width: '90%',
			minHeight: height - 100,
		},
		row: {
			flexDirection: isSmallScreen ? 'col' : 'row',
			width: '100%',
		},
		col1: {
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			width: isSmallScreen ? '100%' : '35%',
		},
		colWithChild: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			width: isSmallScreen ? '100%' : '35%',
		},
		col2: {
			flexDirection: 'row',
			width: isSmallScreen ? '100%' : '65%',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
		},
		colButton: {
			flex: 1,
			flexDirection: 'row',
			width:  isSmallScreen ? '100%' : '65%',
			justifyContent: 'flex-end',
			alignItems: 'flex-end',
		},
		largeText: {
			color: 'white', 
			fontSize: isWideScreen ? 20 : 18, 
			padding: 10,
			paddingBottom: isSmallScreen ? 0 : 3
		},
		smallText: {
			color: 'white', 
			fontSize: isWideScreen ? 16 : 14, 
			paddingHorizontal: 10
		},
		input:  {
			width: isSmallScreen ? '100%' : '80%',
			marginBottom: 3,
			borderColor: colors.accent,
			marginLeft: isSmallScreen ? 10 : 0
		},
		textArea: {
			width: isSmallScreen ? '100%' : '80%',
			marginVertical: 3,
			marginLeft: isSmallScreen ? 10 : 0
		},
		button: {
            flexDirection: 'row',
            flex: 1,
            width: '100%',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingBottom: 10,
			marginBottom: 10,
		},
		titleText: {
			color: 'white',
			fontSize: isWideScreen ? 25 : 20,
			margin: 10,
			fontWeight: 'bold'
		},
		infoText: {
			color: 'white',
			fontSize: isWideScreen ? 16 : 14,
			marginLeft: 10
		}
    })

	const getScreenTitle = () => {
		return isNew ? 'Opret ny profil' : 'Opdater profil'
	}

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})

		if (isNew) {
			setName('')
			setBirthYear('')
			setChildhoodHomeMusic('')
			setChildhoodMusic('')
			setUnwantedMusic('')
			setMusicMemories('')
			setChildhoodResidence('')
		}

		navigation.navigate('ProfileListScreen')
	}

	const openNotifModal = (description) => {
		setNotifModal({
			visible: true,
			title: '',
			description: description,
			isDisappearing: false
		})
	}

	const handleProfileSaving = () => {
		openNotifModal('Profil gemt succesfuldt.')
	}

	return loading ? (
		<Loader />
	) : (
		<ScrollView style={{ backgroundColor: colors.boxes, height: height - 85 }}>
			<View style={styles.profiler}>
				<Text style={styles.titleText}>{ getScreenTitle() }</Text>
				<Text style={styles.infoText}>Information</Text>
				<Text style={styles.infoText}>Her oprettes en musikbiografi for en person. Præferencerne bruger vores system til at generere forslag til relevant musik i de to øverste kvadranter.</Text>
				<Text style={[styles.infoText, {marginTop: 10}]}>Indtast data:</Text>
			</View>
			<View style={styles.container}>
				<View style={styles.row}>
					<View style={styles.col1}>
						<Text style={styles.largeText}>Navn:</Text>
					</View>
					<View style={styles.col2}>
						<Input 
							style={styles.input} 
							value={name} 
							onChangeText={text => setName(text)}
						>
						</Input>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.col1}>
						<Text style={styles.largeText}>Fødselsår:</Text>
					</View>
					<View style={styles.col2}>
						<Input 
							style={styles.input} 
							value={birthYear} 
							onChangeText={text => setBirthYear(text.replace(/[^0-9]/g, ''))}
							keyboardType={'numeric'}
							maxLength={4}
						>
						</Input>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.col1}>
						<Text style={styles.largeText}>Bopæl barndom/ungdom:</Text>
					</View>
					<View style={styles.col2}>
						<TextArea 
							style={styles.textArea} 
							value={childhoodResidence} 
							rows={3} 
							onChangeText={text => setChildhoodResidence(text)}
						>
						</TextArea>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.colWithChild}>
						<View>
							<Text style={styles.largeText}>Hvilken musik kunne du lide i din ungdom?:</Text>
						</View>
						<View>
							<Text style={styles.smallText}>{musicInfo}</Text>
						</View>
					</View>
					<View style={styles.col2}>
						<TextArea 
							style={styles.textArea} 
							value={childhoodMusic} 
							rows={3} 
							onChangeText={text => setChildhoodMusic(text)}
						>
						</TextArea>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.colWithChild}>
						<View>
							<Text style={styles.largeText}>Er der noget musik, du ikke kan lide?:</Text>
						</View>
						<View>
							<Text style={styles.smallText}>{musicQuestion}</Text>
						</View>
					</View>
					<View style={styles.col2}>
						<TextArea 
							style={styles.textArea} 
							value={unwantedMusic} 
							rows={3} 
							onChangeText={text => setUnwantedMusic(text)}
						>
						</TextArea>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.colWithChild}>
						<View>
							<Text style={styles.largeText}>Var der musik i dit barndomshjem?:</Text>
						</View>
						<View>
							<Text style={styles.smallText}>{musicQuestion}</Text>
						</View>
					</View>
					<View style={styles.col2}>
						<TextArea 
							style={styles.textArea} 
							value={childhoodHomeMusic} 
							rows={3} 
							onChangeText={text => setChildhoodHomeMusic(text)}
						>
						</TextArea>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.colWithChild}>
						<View>
							<Text style={styles.largeText}>Har du særlige minder om/med musik?:</Text>
						</View>
					</View>
					<View style={styles.col2}>
						<TextArea 
							style={styles.textArea} 
							value={musicMemories} 
							rows={3} 
							onChangeText={text => setMusicMemories(text)}
						>
						</TextArea>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.colButton}>
						<PrimaryButton
							style={{ 
								width: isWideScreen ? 80 : (isSmallScreen ? '100%' : 65), 
								minHeight: 30,
								marginTop: 20,
								marginBottom: 40
							}}
							title="Gem"
							onPress={() => handleProfileSaving()}
						/>
					</View>
				</View>
			</View>
			
			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					setModalVisibleExternal={undefined}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}

		</ScrollView>
	)
}
