import { useWindowDimensions, Image, View, Pressable } from 'react-native'
import { useEffect, useState } from 'react'
import { Ionicons } from '@expo/vector-icons'

import useLayout from '../../../Hooks/useLayout'
import LinkButton from './components/LinkButton'
import { colors } from '../../../Helpers/variables'

export default function Sidebar() {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const { isSidebarOpen, toggleSidebar, deviceType } = useLayout()

	return (
		<View
			style={{
				flex: 1,
				marginTop: 3,
				// justifyContent: 'space-between',
				// alignItems: 'flex-start'
			}}
		>

			<View style={{
				paddingHorizontal: 5, marginBottom: 5, backgroundColor: deviceType !== 1 ? colors.primary : colors.boxes,
				alignItems: deviceType !== 1 ? 'flex-start' : 'center'
			}}>
				{deviceType !== 1 ? (
					<Image
						source={require('../../../assets/icon-png/MusicMind_logo.png')}
						style={{
							width: 150,
							height: 60
						}}
						resizeMode={'stretch'}
					/>
				) : (
					<Pressable onPress={toggleSidebar} style={{ marginTop: 10, marginLeft: 5 }}>
						<Ionicons name={'ios-menu'} size={30} color={'#ffffff'} />
					</Pressable>
				)}
			</View>
			<View style={{
				//width: '100%',
				alignItems: isWideScreen || isSidebarOpen ? 'flex-start' : 'center',
				//paddingHorizontal: !isWideScreen && isSidebarOpen ? 10 : (isWideScreen ? 25 : 0),
				backgroundColor: colors.boxes,
				paddingTop: 15,
				marginRight: isWideScreen ? 8 : 0,
				marginLeft: 8,
				marginTop: -10,
				height: height - 70
			}}
			>
				<LinkButton open={isSidebarOpen} route="AdminUserScreen" title={'Brugere'} icon={'ios-people'} />
				<LinkButton open={isSidebarOpen} route="AdminUserSoonExpireScreen" title={'Status på udløb'} icon={'time'} />
				<LinkButton
					open={isSidebarOpen}
					route="AdminUserDeactivatedScreen"
					title={'Inaktive brugere'}
					icon={'remove-circle'}
				/>
				<LinkButton open={isSidebarOpen} route="AssignPlaylistUserScreen" title={'Tildel spillelister'} icon={'list'} />
				<LinkButton
					open={isSidebarOpen}
					route="AdminInternalPlaylistContainerScreen"
					title={'Internt miljø'}
					icon={'apps'}
				/>
				{/* <LinkButton
					open={isSidebarOpen}
					route="AdminTrackRequestScreen"
					title={'Anmodede sange'}
					icon={'briefcase'}
				/> */}
			</View>
		</View>
	)
}
